import React, {ChangeEventHandler, useEffect, useState} from 'react';

interface Props {
  value: boolean;
  name?: string;
  text?: string;
  error?: string;
  onInput?: (_: any) => void;
}
const CSlideCheckbox = ({ value, name, text, error, onInput }: Props) => {
  const [inputValue, setInputValue] = useState(false);
  const [hideError, setHideError] = useState(true);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (error) setHideError(false);
  }, [error]);

  const handleInput: ChangeEventHandler<HTMLInputElement> = e => {
    e.persist();
    const { checked } = e.target;
    setInputValue(checked);
    onInput?.(checked);
    setHideError(true);
  };

  return (
    <label className="c-switch">
      <div>
        <input
          type="checkbox"
          name={name}
          onChange={handleInput}
          checked={inputValue}
        />
        <div className="c-slider"/>
        {text && <span
          className="c-checkbox__label"
          dangerouslySetInnerHTML={{
            __html: text
          }}
        />}
      </div>
      {!hideError && <p className="c-form__error">{error}</p>}
    </label>
  );
};

export default CSlideCheckbox;
