import React, { createContext, useReducer, useEffect, useContext, PropsWithChildren } from 'react';
import { Constants } from '../../../utils/constants';
import { AuthContext } from '../../../contexts/useAuthContext';
import {
  getRoleList,
  createStaff,
  getStaffList,
  removeStaff,
  resendInvitation,
  updateStaff,
  reactivateAccount
} from '../../../utils/apiCalls/staff';
import {
  initializeState,
  updateContextState,
  successToast,
  handleError
} from '../../../utils/helpers';
import { StaffAttributeType, StaffContextActionType, StaffStateObjectType } from '../../../utils/types/staff_types';
import { ApiErrorType, FilterItemType } from '../../../utils/types/util_types';

const initialState = {
  updateState: false,
  hasStaff: false,
  items: [],
  totalCount: 0,
  pageNumber: 1,
  refetch: false
};

const StaffReducer = (state: StaffStateObjectType, action: StaffContextActionType): StaffStateObjectType => {
  switch (action.type) {
  case Constants.Actions.Staff.State:
    return { ...state, ...action.payload as StaffStateObjectType };
  case Constants.Actions.Staff.Refetch:
    return {
      ...state,
      refetch: action.payload as boolean
    };
  case Constants.Actions.Staff.ResetUpdateState:
    return {
      ...state,
      updateState: action.payload as boolean
    };
  default:
    return state;
  }
};

const actions = (dispatch: (_: any) => void) => {
  const { state: authState } = useContext(AuthContext);

  const resetUpdateState = (payload: boolean = true) => {
    dispatch({
      type: Constants.Actions.Staff.ResetUpdateState,
      payload
    });
  };

  const returnValue = {
    roleList: async () => {
      const { user } = authState;

      resetUpdateState();

      const roles = await getRoleList(user);

      const { data } = roles;

      return data.data;
    },

    refetchStaff: (payload: boolean = false) => {
      dispatch({
        type: Constants.Actions.Staff.Refetch,
        payload
      });
    },

    staffList: async (
      pageNumber: number = 1,
      query: string = '',
      filterItems = {} as FilterItemType,
      registered: boolean = false
    ) => {
      const { user, currentBusinessId } = authState;
      try {
        resetUpdateState();

        const status = filterItems.status?.toLowerCase() ?? '';

        const staff = await getStaffList(
          user,
          currentBusinessId,
          pageNumber,
          query,
          status,
          registered
        );

        const { data } = staff;

        if (registered) return data.data;

        const payload = {
          hasStaff: data.has_items,
          items: data.data,
          totalCount: data.metadata.total_count,
          pageNumber: data.metadata.current_page,
          refetch: false
        };

        dispatch({
          type: Constants.Actions.Staff.State,
          payload
        });
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    addStaff: async ({ firstName, lastName, email, role }: {
      firstName: string,
      lastName: string,
      email: string,
      role: string
    }) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const staff = await createStaff(user, currentBusinessId, {
          firstName,
          lastName,
          email,
          role
        });

        const { data } = staff;

        if (data && data.data.attributes) {
          dispatch({
            type: Constants.Actions.Staff.Refetch,
            payload: true
          });
        }

        successToast(data.message);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    editStaff: async (employeeId: number, { firstName, lastName, email, role }: {
      firstName: string,
      lastName: string,
      email: string,
      role: string
    }) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const staff = await updateStaff(user, currentBusinessId, employeeId, {
          firstName,
          lastName,
          email,
          role
        });

        const { data } = staff;

        if (data && data.data.attributes) {
          dispatch({
            type: Constants.Actions.Staff.Refetch,
            payload: true
          });
        }

        successToast(data.message);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    resendInvite: async (employeeId: number) => {
      const { user } = authState;

      try {
        resetUpdateState();
        const result = await resendInvitation(user, employeeId);

        const { data } = result;

        dispatch({
          type: Constants.Actions.Staff.Refetch,
          payload: true
        });

        successToast(data.message);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    deleteStaff: async (staff: StaffAttributeType) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        await removeStaff(user, currentBusinessId, staff.id);

        returnValue.staffList(1);

        successToast(
          `Successfully ${
            staff.status === Constants.Statuses.Staff.Active
              ? Constants.Statuses.Staff.Deactivated
              : Constants.Statuses.Staff.Deleted
          } staff`
        );

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    reactivateStaff: async (id: number) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const result = await reactivateAccount(user, currentBusinessId, id);

        const { data } = result;

        returnValue.staffList(1);

        successToast(data.message);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    }
  };
  return returnValue;
};

type StaffContextActions = ReturnType<typeof actions>
type StaffContextType = {state: StaffStateObjectType} & StaffContextActions
export const StaffContext = createContext<StaffContextType>({} as StaffContextType);

export default function StaffProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useReducer(
    StaffReducer,
    initializeState(Constants.StorageKey.Staff, initialState)
  );

  useEffect(() => {
    if (state.updateState)
      updateContextState(Constants.StorageKey.Staff, state);
  }, [state]);

  return (
    <StaffContext.Provider value={{ state, ...actions(dispatch) }}>
      {children}
    </StaffContext.Provider>
  );
}
