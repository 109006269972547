import { Constants } from './constants';
import {toast, ToastOptions} from 'react-toastify';
import {AuthStateObjectType, ProfileAttributeType} from './types/auth_types';
import {Component} from 'react';
import {ApiErrorType, CStateType} from './types/util_types';

export function getLocalStorageItem(key: string) {
  const val = localStorage.getItem(key);

  if (!val) return;

  try {
    return JSON.parse(val);
  } catch (error) {
    return val;
  }
}

export function setLocalStorageItem(key: string, value: string) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    return false;
  }
}

export const initializeState = (key: string, initial: CStateType) => {
  const mainState = getLocalStorageItem(Constants.StorageKey.MainKey);
  if (!mainState) setLocalStorageItem(Constants.StorageKey.MainKey, '');

  return (mainState && mainState[key]) || initial;
};

export const updateContextState = (key: string, state: CStateType) => {
  const mainState = getLocalStorageItem(Constants.StorageKey.MainKey);
  const updatedState = { ...mainState, [key]: state };

  setLocalStorageItem(Constants.StorageKey.MainKey, updatedState);
};

export const getContextState = (context: string, key: string) => {
  const mainState = getLocalStorageItem(Constants.StorageKey.MainKey);
  if (!mainState) return null;

  const ctx = mainState[context];
  return ctx ? ctx[key] : null;
};

export const toastConfig = {
  position: 'top-center',
  autoClose: 2500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}  as ToastOptions;

export const handleError = (error: ApiErrorType) => {
  if (!error) return;
  const { errors, message } = error.data;

  if (errors) {
    const { base, ...formErrors } = errors;
    if (base) errorToast(base.join('\r\n'));
    if (formErrors) throw formErrors;
  }
  if (message) throw message;
};

export const successToast = (message: string) => {
  toast.success(message, toastConfig);
};

export const errorToast = (message: string) => {
  toast.error(message, toastConfig);
};

type CLinkType = {
  title: string,
  component: Component,
  path: string,
  access: string
}
export const filterLinksWithSubscription = (authState: AuthStateObjectType, links: CLinkType[]) => {
  if (isSubscriptionEnabled) {
    const { subscription } = authState.user;

    return links.filter(({ access }) => {
      return !(
        subscription?.attributes?.plan_code === process.env.REACT_APP_PAYSTACK_PLAN_BASIC_CODE
          && access === Constants.Access.NotBasicAccount
      );
    });
  }

  return links.filter(({ access }) => access);
};

export const urlParams = (query_string: string) => {
  const params = new URL(window.location.href).searchParams;
  return params.get(query_string.toLowerCase());
};

export const getObjectId = () => {
  const link = window.location.href.split('/');
  const id = link[link.length - 1];
  return isNaN(Number(id)) ? id : Number(id);
};

export const getActiveClasses = (allClasses: object) => {
  return Object.entries(allClasses).reduce(
    (acc, [key, value]) => {
      if (value) {
        acc += ` ${key}`;
      }
      return acc;
    },
    ''
  );
};

export const isSubscriptionEnabled =
  getLocalStorageItem(Constants.StorageKey.PresetData)?.paystack_subscription_enabled;

export const dateFromToday = (count: number = 0) => {
  const today = new Date();
  return new Date(today.getTime() + (24 * count * 60 * 60 * 1000));
};

export const paginateArray = (
  range: number,
  setter: (_:any) => void,
  pageNumber: number = 1,
  data: any[] = []
) => {
  const end_offset = pageNumber * range;
  const start_offset = end_offset - range;

  setter(data.slice(start_offset, end_offset));
};

export const logo = 'https://corna-production.s3.eu-central-1.amazonaws.com/logo.svg';

export const getAvatar = (profileAttributes: ProfileAttributeType) => {
  if (profileAttributes.avatar) return profileAttributes.avatar.split('?')[0];

  if (profileAttributes.gender === Constants.Gender.Female) {
    return 'https://corna-production.s3.eu-central-1.amazonaws.com/female-avatar.svg';
  } else if (profileAttributes.gender === Constants.Gender.Male) {
    return 'https://corna-production.s3.eu-central-1.amazonaws.com/male-avatar.svg';
  } else {
    return 'https://corna-production.s3.eu-central-1.amazonaws.com/avatar.svg';
  }
};
