import {UploadUrlType} from './types/util_types';

export const truncateString = (str: string, num: number) => {
  if (str.length - 3 <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

export const imageFileName = (value: UploadUrlType, imagePosition?: number) => {
  return typeof value === 'object' ? `${value.attributes.uuid_token}-${imagePosition}` : value;
};

export const pluralize = (count: number, noun: string, suffix: string = 's') => {
  return `${count} ${noun}${count !== 1 ? suffix : ''}`;
};

export const convertTime = (number: number) => {
  const hourCount = Math.floor(number / 60);
  const minuteCount = number % 60;

  return { hourCount: hourCount, minuteCount: minuteCount };
};

export const convertTimeTo12HourFormat = (dateString: string, timezone: string) => {
  const date = new Date(dateString);

  return Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: timezone!
  }).format(date).replace(' ','').toLowerCase();
};

export const monthDayYear = (dateString: string, timezone: string) => {
  const date = new Date(dateString);

  return Intl.DateTimeFormat('en', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    timeZone: timezone
  }).format(date);
};

export const weekMonthDayYear = (dateString: string, timezone: string) => {
  const date = new Date(dateString);

  return Intl.DateTimeFormat('en', {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    timeZone: timezone
  }).format(date).replace(/,/g,'');
};

export const numericDate = (dateString: string, timezone: string) => {
  const date = new Date(dateString);

  return Intl.DateTimeFormat('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timezone!
  }).format(date).replace(/\//g,'-');
};

export const convertDurationToHrsAndMins = (time: number) => {
  const hrsAndMins = convertTime(time);

  const hourCount = hrsAndMins.hourCount;
  const minuteCount = hrsAndMins.minuteCount;

  const minutes = pluralize(minuteCount, 'min');
  const hours = pluralize(hourCount, 'hr');

  if (hourCount === 0) {
    return minutes;
  } else if (minuteCount === 0) {
    return hours;
  }

  return `${hours} ${minutes}`;
};

export const chartLabelFormatter = (label: string, format: string) => {
  if (!format) return label;

  if (format === 'money') {
    return `\u20A6${label.toLocaleString()}`;
  }

  if (format === 'percentage') {
    return `${label.toLocaleString()}%`;
  }
};

export const titleize = (value: string) => {
  if (!value.length) return '';

  return value[0].toUpperCase() + value.slice(1).toLowerCase();
};

export const cardLast4 = (last4: string) => {
  return `xxxx-xxxx-xxxx-${last4}`;
};

export const formatRatingScore = (value: number) => {
  return parseFloat(`${value}`).toFixed(2);
};
