let autoComplete: any;

export const loadScript = (url: string, callback: () => void) => {
  const script: any = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  if (document.getElementsByTagName('script').length > 5) return;
  document.getElementsByTagName('head')[0].appendChild(script);
};

export function handleScriptLoad(autocompleteRef: any, setAutoCompleteValues: any) {
  autoComplete = new (window as any).google.maps.places.Autocomplete(
    autocompleteRef.current,
    { componentRestrictions: { country: 'NG' } }
  );

  autoComplete.setFields(['address_components', 'formatted_address', 'geometry', 'utc_offset_minutes']);
  autoComplete.addListener('place_changed', () =>
    handlePlaceSelect(setAutoCompleteValues)
  );
}

export async function handlePlaceSelect(setAutoCompleteValues: any) {
  const addressObject = autoComplete.getPlace();
  const result = autoCompleteAddressValues(addressObject);
  setAutoCompleteValues(result);
}

const extractFromAddress = (components: object, type: string, format = 'long_name') => {
  if ((Object.keys(components).length === 0) || !Array.isArray(components)) return '';

  return components.filter((component) => {
    return component.types.indexOf(type) === 0;
  }).map((item) => format === 'long_name' ? item.long_name : item.short_name).pop() || '';
};

export const autoCompleteAddressValues = (addressObject: any) => {
  const addressValues = addressObject.address_components;
  const geometryValues = addressObject.geometry.location;

  if (Object.keys(addressValues).length === 0) return;

  return {
    address1: setAddress1Value(addressValues),
    city: setCityValue(addressValues),
    timezone: addressObject?.utc_offset_minutes ? (addressObject?.utc_offset_minutes) * 60 : null,
    postalCode: extractFromAddress(addressValues, 'postal_code'),
    stateCode: extractFromAddress(addressValues, 'administrative_area_level_1', 'short_name'),
    state: extractFromAddress(addressValues, 'administrative_area_level_1'),
    countryCode: extractFromAddress(addressValues, 'country', 'short_name'),
    country: extractFromAddress(addressValues, 'country'),
    latitude: geometryValues.lat(),
    longitude: geometryValues.lng()
  };
};

const setAddress1Value = (result: object) => {
  if (extractFromAddress(result, 'street_number').length === 0 ||
    extractFromAddress(result, 'route').length === 0) return '';
  return `${extractFromAddress(result, 'street_number')} ${extractFromAddress(result, 'route')}` || '';
};

const setCityValue = (result: object) => {
  let city;

  if ((extractFromAddress(result, 'neighborhood') === extractFromAddress(result, 'administrative_area_level_2')) ||
    (extractFromAddress(result, 'administrative_area_level_2').length === 0)) {
    city = extractFromAddress(result, 'neighborhood');
  } else if (extractFromAddress(result, 'neighborhood') && extractFromAddress(result, 'administrative_area_level_2')) {
    city = `${extractFromAddress(result, 'neighborhood')}, ${extractFromAddress(result, 'administrative_area_level_2')}` || '';
  } else if (extractFromAddress(result, 'neighborhood').length === 0) {
    city = extractFromAddress(result, 'administrative_area_level_2');
  }

  return city;
};
