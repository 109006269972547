import React from 'react';
import EyeOpenIcon from '../assets/images/eye-open.svg';
import EyeSlashIcon from '../assets/images/eye-slash.svg';

interface Props {
  isDateFilter: boolean;
  showCalendarIcon: boolean;
  onToggle?: () => void;
}

const CalendarToggle = ({
  isDateFilter,
  showCalendarIcon,
  onToggle
}: Props) => {
  return (
    <React.Fragment>
      {isDateFilter && <img
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        type="password"
        className="pointer ps-3"
        src={showCalendarIcon ? EyeOpenIcon : EyeSlashIcon}
        alt="show calendar"
        title={showCalendarIcon ? 'Hide calendar' : 'Show calendar'}
        onClick={onToggle}
      />}
    </React.Fragment>
  );
};

export default CalendarToggle;
