import React, { useContext, useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import check from '../../assets/images/dashboard/check-circle.svg';
import uncheck from '../../assets/images/dashboard/Ellipse.svg';
import arrowForwardIcon from '../../assets/images/dashboard/arrow-right.svg';
import checkmarkIcon from '../../assets/images/dashboard/checkmark.svg';
import mdname from '../../assets/images/dashboard/Ijeoma-Arisah.svg';
import CButton from '../../shared/forms/Button';
import { AuthContext } from '../../contexts/useAuthContext';
import { BusinessContext } from '../settings/business-profile/useBusinessContext';

const DashboardWelcome = () => {
  const { state: authState } = useContext(AuthContext);
  const { getBusiness } = useContext(BusinessContext);
  const [loading, setLoading] = useState(false);
  const [businessDetailsComplete, setBusinessDetailsComplete] = useState(false);
  const [firstCategoryComplete, setFirstCategoryComplete] = useState(false);
  const [paymentDetailsComplete, setPaymentDetailsComplete] = useState(false);
  const [tasksComplete, setTasksComplete] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const business = await getBusiness();
      if (Object.keys(business).length === 0) return;

      const businessAttributes = business.attributes;

      setBusinessDetailsComplete(businessAttributes.set_up_complete);
      setFirstCategoryComplete(businessAttributes.category_added);
      setPaymentDetailsComplete(businessAttributes.bank_detail_added);
      setTasksComplete(businessAttributes.onboarding_tasks_completed);
      setLoading(false);
    })();
  }, [authState.currentBusiness]);


  const tasks = [
    {
      text: 'Update business details',
      completed: businessDetailsComplete,
      link: '/settings/business-profile'
    },
    {
      text: 'Create your first category',
      completed: firstCategoryComplete,
      link: '/services'
    },
    {
      text: 'Add your payment information',
      completed: paymentDetailsComplete,
      link: '/settings/payments'
    }
  ];

  return (
    !tasksComplete && !loading && (
      <div className="welcome-bg">
        <Row>
          <Col md={6} className="mb-4 mb-md-0">
            <Card className="home-card welcome-card-inner">
              <Card.Body>
                <Card.Title>
                  <span>Thank you for choosing Corna</span>
                </Card.Title>
                <Card.Text className="welcome-card-text">
                  <span className="welcome-p-color">
                    Businesses of all sizes use Corna to take bookings &
                    Appointments online, including some of Africa's biggest
                    brands.
                    <br />
                    <br />
                    Thank you for choosing Corna. We look forward to being a
                    reliable growth engine and partner to you, your team, and
                    your business.
                    <br />
                    <br />
                  </span>
                  <span>
                    <img src={mdname} alt="mdname" className="mdname" />
                    <br />
                  </span>
                  <span>Managing Director</span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="home-card welcome-card-inner">
              <Card.Body>
                <Card.Title>
                  <span>Onboarding Tasks</span>
                </Card.Title>
                <div className="welcome-card-text">
                  <p className="welcome-p-color mb-3">
                    Get your Corna business account up and running by completing
                    the onboarding tasks.
                  </p>
                  {tasks.map(({ text, completed, link }, index) => (
                    <Row className="welcome-card-todo" key={index}>
                      <Col xs={8} className="chk-box-col">
                        <img
                          src={completed ? check : uncheck}
                          alt="custom-checkbox"
                          className="d-none d-xl-block uncheck-checkbox"
                        />
                        <div>{text}</div>
                      </Col>
                      <Col xs={4} className="btn-col">
                        <span className="d-none d-xl-inline-block">
                          <CButton
                            color="primary"
                            smallText
                            small
                            styles={{
                              pointerEvents: `${completed ? 'none' : 'auto'}`
                            }}
                            href={link}
                          >
                            {completed ? 'Completed' : 'Go to task'}
                          </CButton>
                        </span>
                        <span className="d-xl-none">
                          <CButton
                            color="primary"
                            icon={completed}
                            small={!completed}
                            styles={{
                              pointerEvents: `${completed ? 'none' : 'auto'}`
                            }}
                            href={link}
                          >
                            <img
                              src={completed ? checkmarkIcon : arrowForwardIcon}
                              alt="Continue"
                            />
                          </CButton>
                        </span>
                      </Col>
                    </Row>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    )
  );
};

export default DashboardWelcome;
