import React, { useContext, useEffect, useState } from 'react';
import CButton from '../../shared/forms/Button';
import { CTable, CTableRow, CTableData } from '../../shared/CTable';
import { truncateString } from '../../utils/stringManipulation';
import { AuthContext } from '../../contexts/useAuthContext';
import { ServiceContext } from './useServiceContext';
import EmptyState from '../../shared/EmptyState';
import { Constants } from '../../utils/constants';
import CConfirmationModal from '../../shared/forms/ConfirmationModal';
import CategoryData from './CategoryData';
import {SubCategoryAttributeType, SubCategoryStateObjectType} from '../../utils/types/service_types';

interface Props {
  onAddCategory?: () => void
}
const CategoryTable = ({ onAddCategory }: Props) => {
  const { state: authState, isAdmin } = useContext(AuthContext);
  const { state, categoryList, deleteCategory, refetchCategories } = useContext(
    ServiceContext
  );
  const [categoryConfig, setCategoryConfig] =
    useState<SubCategoryStateObjectType>(state.categories);
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [categoryId, setCategoryId] = useState<number>();
  const [category, setCategory] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchData();
      setLoading(false);
    })();
  }, [authState.currentBusiness]);

  useEffect(() => {
    (async () => {
      const { categories = {} as SubCategoryStateObjectType } = state;
      const { items = [], totalCount = 0, pageNumber, refetch } = categories;
      setCategoryConfig({ items, totalCount, pageNumber } as SubCategoryStateObjectType);
      if (refetch) {
        refetchCategories();
        await fetchData(pageNumber);
      }
    })();
  }, [state.categories]);

  const fetchData = async (pageNumber: number = 1, query: string = '') => {
    await categoryList(pageNumber, query);
  };

  const tableConfig = {
    title: Constants.Table.Title.Categories,
    theadData: Constants.Table.Header.Categories,
    totalCount: categoryConfig.totalCount,
    pageRange: Constants.PerPageCount.Categories,
    OnSearch: fetchData,
    onPageChange: async (page: number, query: string) => {
      if (categoryConfig.totalCount > 0) await fetchData(page, query);
    }
  };

  const prepareEdit = (attributes: SubCategoryAttributeType) => {
    setCategoryId(attributes.id);
    setCategory(attributes.name);
    setShowEditModal(true);
  };

  const prepareDelete = (attributes: SubCategoryAttributeType) => {
    setCategoryId(attributes.id);
    setShowConfirmationModal(true);
  };

  const handleDeleteCategory = async () => {
    try {
      setLoading(true);
      await deleteCategory(categoryId as number);
      setLoading(false);
      setShowConfirmationModal(false);
    } catch (error) {
      setLoading(false);
      setShowConfirmationModal(false);
    }
  };

  return (
    <div>
      {!state.categories.hasCategories && !loading && onAddCategory ? (
        <EmptyState
          type={Constants.Table.Empty.CategoriesTable}
          onClick={onAddCategory}
        />
      ) : (
        <React.Fragment>
          <CConfirmationModal
            show={showConfirmationModal}
            type="category"
            loading={loading}
            onClick={handleDeleteCategory}
            onClose={() => setShowConfirmationModal(false)}
          />
          <CategoryData
            show={showEditModal}
            onClose={() => setShowEditModal(false)}
            id={categoryId as number}
            name={category}
          />
          <CTable {...tableConfig}>
            {categoryConfig.items.map(({ attributes }, index) => (
              <CTableRow key={index}>
                <CTableData styles={{ width: '40%' }}>
                  <strong data-title={attributes.name}>
                    {truncateString(attributes.name, 25)}
                  </strong>
                </CTableData>
                <CTableData>{attributes.service_count}</CTableData>
                {isAdmin() ? (
                  <CTableData classes="actions">
                    <CButton
                      small
                      smallText
                      onClick={() => prepareEdit(attributes)}
                    >
                      Edit
                    </CButton>
                    <CButton
                      small
                      smallText
                      color="transparent"
                      onClick={() => prepareDelete(attributes)}
                    >
                      Delete
                    </CButton>
                  </CTableData>
                ): <CTableData />}
              </CTableRow>
            ))}
          </CTable>
        </React.Fragment>
      )}
    </div>
  );
};

export default CategoryTable;
