import { app, getAnalytics, logEvent, setUserProperties, setUserId } from '../utils/firebase';

const analytics = getAnalytics(app);

type TrackUserType = {
  userId: number,
  userType: string,
  userEmail: string
}

const useAnalytics = () => {
  const logAnalyticsEvent = (eventName: string, data: any) => {
    logEvent(analytics, eventName, data);
  };

  const trackUser = (data = {} as TrackUserType) => {
    setUserId(analytics, `${data.userId}`);
    setUserProperties(analytics, data, { global: true });
  };

  return {
    analytics,
    logAnalyticsEvent,
    trackUser
  };
};

export default useAnalytics;
