import React, {createContext, useReducer, useEffect, useContext, PropsWithChildren} from 'react';
import {Constants, UtcTimezone} from '../../utils/constants';
import { AuthContext } from '../../contexts/useAuthContext';
import {
  findBooking,
  getBookingList,
  scoreBooking,
  bookingCancellation
} from '../../utils/apiCalls/booking';
import { initializeState, updateContextState, handleError } from '../../utils/helpers';
import {numericDate} from '../../utils/stringManipulation';
import {ApiErrorType, RatingFormAttributeType} from '../../utils/types/util_types';
import {
  BookingActionType,
  BookingResetUpdateActionType,
  BookingStateObjectType,
  BookingStateType
} from '../../utils/types/booking_types';

const initialState = {
  updateState: false,
  hasBookings: false,
  items: [],
  totalCount: 0,
  pageNumber: 1
};

const BookingReducer = (
  state: BookingStateObjectType,
  action: BookingActionType | BookingResetUpdateActionType
) => {
  switch (action.type) {
  case Constants.Actions.Booking.State:
    return { ...state, ...action.payload as BookingStateObjectType };
  case Constants.Actions.Booking.ResetUpdateState:
    return {
      ...state,
      updateState: action.payload as boolean
    };
  default:
    return { ...state };
  }
};

const actions = (dispatch: (_: any) => void) => {
  const { state: authState } = useContext(AuthContext);

  const resetUpdateState = (payload: boolean = true) => {
    dispatch({
      type: Constants.Actions.Booking.ResetUpdateState,
      payload
    });
  };

  const returnValue = {
    bookingList: async (
      pageNumber: number = 1,
      query: string = '',
      status: string = 'confirmed',
      from: Date | string = new Date(),
      to: Date | string = new Date(),
      fetchAll: boolean = false
    ) => {
      const { user, currentBusinessId } = authState;

      const fromDate = typeof from === 'string' ? from : numericDate(from.toLocaleDateString(), UtcTimezone);
      const toDate = typeof to === 'string' ? to : numericDate(to.toLocaleDateString(), UtcTimezone);

      try {
        resetUpdateState();
        const bookings = await getBookingList(
          user,
          currentBusinessId,
          pageNumber,
          query,
          status,
          fromDate,
          toDate,
          fetchAll
        );

        const { data } = bookings;

        const payload = {
          hasBookings: data.has_items,
          items: data.data,
          totalCount: data.metadata.total_count,
          pageNumber: data.metadata.current_page
        };

        dispatch({
          type: Constants.Actions.Booking.State,
          payload
        });

        return payload;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    getBooking: async (id: number) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const booking = await findBooking(user, currentBusinessId, id);

        const { data } = booking;

        return data.data;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    rateBooking: async (id: number, ratingAttributes: RatingFormAttributeType) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        await scoreBooking(user, currentBusinessId, id, ratingAttributes);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    cancelBooking: async (id: number, note: string) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        await bookingCancellation(user, currentBusinessId, id, note);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    }
  };
  return returnValue;
};

type BookingContextActions = ReturnType<typeof actions>
type BookingContextType = BookingStateType & BookingContextActions
export const BookingContext = createContext<BookingContextType>({} as BookingContextType);

export default function BookingProvider({ children, value }: PropsWithChildren<{value?: BookingStateObjectType}>) {
  const [state, dispatch] = useReducer(
    BookingReducer,
    initializeState(
      Constants.StorageKey.Booking,
      {initialState, ...(value ?? {})} as BookingStateObjectType
    )
  );

  useEffect(() => {
    if (state.updateState)
      updateContextState(Constants.StorageKey.Booking, state as BookingStateObjectType);
  }, [state]);

  return (
    <BookingContext.Provider value={{ state, ...actions(dispatch) }}>
      {children}
    </BookingContext.Provider>
  );
}
