export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const setFormValue = (setFormData, key, value) =>
  setFormData(prev => ({ ...prev, [key]: value }));

export const getFormValue = (formData, key) => (formData[key]);

export function debounce(fxn, time) {
  let timeoutId;

  return wrapper;

  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      timeoutId = null;
      fxn(...args);
    }, time);
  }
}
