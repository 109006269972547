import React, { useContext } from 'react';
import { AuthContext } from '../../../contexts/useAuthContext';
import { Card } from 'react-bootstrap';
import PaymentTable from './PaymentTable';
import {cardLast4} from '../../../utils/stringManipulation';

const SettingsSubscription = () => {
  const { state: authState } = useContext(AuthContext);

  return (
    <div className="business-profile">
      <Card className="home-card mb-4">
        <Card.Body>
          <p className="text-dark">
            <strong>Current plan: </strong>
            <span>{authState.user.subscription?.attributes.plan_name}</span>
          </p>
          <p className="text-dark mt-3">
            <strong>Next payment date: </strong>
            <span>{authState.user.subscription?.attributes.next_payment_date}</span>
          </p>
          <p className="text-dark mt-3">
            <strong>Current card: </strong>
            <span>
              {authState.user.subscription?.attributes.card_last4 &&
                cardLast4(authState.user.subscription.attributes.card_last4)}
            </span>
          </p>
        </Card.Body>
      </Card>
      <PaymentTable authState={authState} />
    </div>
  );
};

export default SettingsSubscription;
