import React, {useState, useContext, useEffect, FormEvent} from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Form, Col, Row } from 'react-bootstrap';
import {
  validateEmail,
  setFormValue,
  getFormValue
} from '../utils/formHelpers';
import {isSubscriptionEnabled, logo, urlParams} from '../utils/helpers';
import { AuthContext } from '../contexts/useAuthContext';
import { Constants } from '../utils/constants';
import CCard from '../shared/CCard';
import CFormInput from '../shared/forms/Input';
import CFormSelect from '../shared/forms/Select';
import CCheckbox from '../shared/forms/Checkbox';
import CButton from '../shared/forms/Button';
import AlertComponent from '../shared/AlertComponent';
import useAnalytics from '../hooks/useAnalytics';
import {CategoryAttributeType} from '../utils/types/service_types';
import usePasswordValidator from '../hooks/usePasswordValidator';

type FormErrorKey =
  'first_name' |
  'last_name' |
  'email' |
  'password' |
  'businesses.name' |
  'businesses.category' |
  'businesses.size';
type FormErrorType = Record<FormErrorKey, string[]> & { agreement: string }

const Register = () => {
  const [agreement, setAgreement] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registrationForm, setRegistrationForm] = useState({});
  const [categories, setCategories] = useState<CategoryAttributeType[]>([]);
  const [businessSizes, setBusinessSizes] = useState<string[]>([]);
  const [formError, setFormError] = useState('');
  const [formErrors, setFormErrors] = useState<Partial<FormErrorType>>({});
  const { register, retrieveEmployee, state, getPresetData } = useContext(AuthContext);
  const { trackUser } = useAnalytics();

  const history = useHistory();
  const passwordValidator = usePasswordValidator();

  useEffect(() => {
    (async () => {
      await getPresetData();
      const invitationToken = urlParams('invitation_token');

      if (invitationToken) {
        try {
          await retrieveEmployee(invitationToken);
          const EmployeeData = localStorage.getItem(
            Constants.StorageKey.Employee
          );

          if (EmployeeData) {
            const employee = JSON.parse(EmployeeData);

            setFormValue(setRegistrationForm, 'email', employee.email);
            setFormValue(setRegistrationForm, 'firstName', employee.first_name);
            setFormValue(setRegistrationForm, 'lastName', employee.last_name);
            setFormValue(
              setRegistrationForm,
              'businessName',
              employee.business_name
            );
          }
        } catch (error) {
          setFormError(error as string);
        }
      } else if (invitationToken !== null && invitationToken.length === 0) {
        setFormError('This is an invalid invitation. Contact your administrator');
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!state) return;

      if (state.presetData) {
        const {categories = [], business_sizes = []} = state.presetData;
        const attributes = categories.map(({attributes}) => attributes);
        setBusinessSizes(business_sizes);
        setCategories(attributes);
      }
    })();
  }, [state]);

  useEffect(() => {
    passwordValidator.validate(getFormValue(registrationForm, 'password'));
  }, [getFormValue(registrationForm, 'password')]);

  const isFormValid = () => {
    const invitationToken = urlParams('invitation_token');
    return invitationToken ? isStaffDataValid() : isAdminDataValid();
  };

  const isPasswordValid = () => {
    return passwordValidator.isValid(getFormValue(registrationForm, 'password'));
  };

  const isStaffDataValid = () => {
    const isEmailValid = validateEmail(getFormValue(registrationForm, 'email'));
    return (
      isEmailValid &&
      isPasswordValid() &&
      !!getFormValue(registrationForm, 'password') &&
      !!getFormValue(registrationForm, 'firstName') &&
      !!getFormValue(registrationForm, 'lastName') &&
      agreement
    );
  };

  const isAdminDataValid = () => {
    return (
      isStaffDataValid() &&
      !!getFormValue(registrationForm, 'businessName') &&
      !!getFormValue(registrationForm, 'businessCategory') &&
      !!getFormValue(registrationForm, 'businessSize')
    );
  };

  const handleRegister = async (e: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    setFormErrors({});
    e.preventDefault();
    try {
      const result = await register({
        email: getFormValue(registrationForm, 'email'),
        password: getFormValue(registrationForm, 'password'),
        firstName: getFormValue(registrationForm, 'firstName'),
        lastName: getFormValue(registrationForm, 'lastName'),
        businessName: getFormValue(registrationForm, 'businessName'),
        businessSize: getFormValue(registrationForm, 'businessSize'),
        businessCategory: getFormValue(registrationForm, 'businessCategory'),
        agreement
      });
      setLoading(false);

      if (result) {
        localStorage.setItem(Constants.StorageKey.SessionRefreshCount, String(0));

        const data = {
          userId: result.user.id,
          userType: result.user.is_admin ? 'Admin' : 'Employee',
          userEmail: result.user.email
        };

        trackUser(data);

        isSubscriptionEnabled ?
          history.push(Constants.Links.Auth.Path.SelectPlan) :
          history.push(Constants.Links.Sidebar.Path.Home);
      }
    } catch (error) {
      setLoading(false);
      setFormErrors(error as FormErrorType);
    }
  };

  return (
    <div className="c-auth__gradient">
      <CCard
        content={
          <div className="c-auth__content">
            <img src={logo} alt="Logo" className="app-logo" />
            <br />
            <br />
            <h1>Sign up.</h1>
            <p className="mt-2">Create an account with Corna</p>
            <br />
            <Form onSubmit={handleRegister}>
              {formError && (
                <AlertComponent
                  text={formError}
                  type="danger"
                  showIcon={false}
                  onClose={() => setFormError('')}
                />
              )}

              <Row className="justify-content-between">
                <Col className="mb-3" md="6">
                  <CFormInput
                    placeholder="First name"
                    name="firstName"
                    value={getFormValue(registrationForm, 'firstName')}
                    readOnly={!!localStorage.getItem(
                      Constants.StorageKey.Employee
                    )}
                    errors={formErrors['first_name']}
                    onInput={value =>
                      setFormValue(setRegistrationForm, 'firstName', value)
                    }
                  />
                </Col>
                <Col className="mb-3" md="6">
                  <CFormInput
                    placeholder="Last name"
                    name="lastName"
                    value={getFormValue(registrationForm, 'lastName')}
                    readOnly={!!localStorage.getItem(
                      Constants.StorageKey.Employee
                    )}
                    errors={formErrors['last_name']}
                    onInput={value =>
                      setFormValue(setRegistrationForm, 'lastName', value)
                    }
                  />
                </Col>
              </Row>
              <div className="mb-3">
                <CFormInput
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  value={getFormValue(registrationForm, 'email')}
                  readOnly={!!localStorage.getItem(Constants.StorageKey.Employee)}
                  errors={formErrors['email']}
                  onInput={value =>
                    setFormValue(setRegistrationForm, 'email', value)
                  }
                />
              </div>
              <div className="mb-3">
                <CFormInput
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={getFormValue(registrationForm, 'password')}
                  errors={formErrors['password']}
                  onInput={value =>
                    setFormValue(setRegistrationForm, 'password', value)
                  }
                />
                {!isPasswordValid() &&
                  <div>
                    <li style={{ color: `${passwordValidator.lowerCaseWarning ? 'red' : 'green'}` }}>
                      contain at least one small letter
                    </li>
                    <li style={{ color: `${passwordValidator.upperCaseWarning ? 'red' : 'green'}` }}>
                      contain at least one capital letter
                    </li>
                    <li style={{ color: `${passwordValidator.digitWarning ? 'red' : 'green'}` }}>
                      contain at least one number letter
                    </li>
                    <li style={{ color: `${passwordValidator.specialCharWarning ? 'red' : 'green'}` }}>
                      contain at least one symbol (any character not a number or letter)
                    </li>
                    <li style={{ color: `${passwordValidator.passwordCountWarning ? 'red' : 'green'}` }}>
                      contain at least 7 characters
                    </li>
                  </div>
                }
              </div>
              <div className="mb-3">
                <CFormInput
                  placeholder="Business Name"
                  name="businessName"
                  value={getFormValue(registrationForm, 'businessName')}
                  errors={formErrors['businesses.name']}
                  readOnly={!!localStorage.getItem(Constants.StorageKey.Employee)}
                  onInput={value =>
                    setFormValue(setRegistrationForm, 'businessName', value)
                  }
                />
              </div>
              <div
                className={
                  localStorage.getItem(Constants.StorageKey.Employee)
                    ? 'd-none'
                    : ''
                }
              >
                <div className="mb-3">
                  <CFormSelect
                    placeholder="Business Size"
                    name="businessSize"
                    value={getFormValue(registrationForm, 'businessSize')}
                    errors={formErrors['businesses.size']}
                    onInput={value =>
                      setFormValue(setRegistrationForm, 'businessSize', value)
                    }
                    options={businessSizes}
                  />
                </div>
                <div className="mb-3">
                  <CFormSelect
                    placeholder="Business Type"
                    name="businessCategory"
                    value={getFormValue(registrationForm, 'businessCategory')}
                    errors={formErrors['businesses.category']}
                    onInput={value =>
                      setFormValue(
                        setRegistrationForm,
                        'businessCategory',
                        value
                      )
                    }
                    options={categories}
                    optionValue="id"
                    optionName="name"
                  />
                </div>
              </div>
              <div className="mb-3 text-start">
                <CCheckbox
                  text="<span> I agree to the Corna <a href='#n' class='primary'><strong>terms and conditions</strong></a></span>"
                  name="agreement"
                  value={agreement}
                  error={formErrors['agreement']}
                  onInput={setAgreement}
                />
              </div>
              <div className="text-start">
                <CButton
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={!isFormValid()}
                >
                  Create Account
                </CButton>
              </div>
            </Form>
          </div>
        }
      />
      <br />
      <div>
        <p className="form-meta">
          Already have an account?{' '}
          <a href={Constants.Links.Auth.Path.Login} className="link-text">
            Sign in
          </a>
        </p>
      </div>
    </div>
  );
};

export default withRouter(Register);
