import React, {FormEvent, useContext, useEffect, useState} from 'react';
import CModal from '../../../shared/forms/Modal';
import CButton from '../../../shared/forms/Button';
import CFormSelect from '../../../shared/forms/Select';
import {AuthContext} from '../../../contexts/useAuthContext';
import {PlanContext} from './usePlanContext';
import {usePaystackPayment} from 'react-paystack';
import {Constants} from '../../../utils/constants';
import {useHistory} from 'react-router-dom';
import Loader from '../../../shared/Loader';
import {errorToast} from '../../../utils/helpers';
import {UserAttributeType} from '../../../utils/types/auth_types';
import {
  CardAttributeType,
  CardType,
  PaystackOnSuccessResponseType,
} from '../../../utils/types/subscription_types';
import {PaystackProps} from 'react-paystack/dist/types';

interface Props {
  user: UserAttributeType;
  show: boolean,
  onClose: () => void,
  planId: number
}
const PaymentData = ({ user, show, onClose, planId }: Props) => {
  const [cardId, setCardId] = useState<number>();
  const [reference, setReference] = useState('');
  const [loading, setLoading] = useState(false);
  const [paystackTrigger, setPaystackTrigger] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cards, setCards] = useState([]);

  const { getAdmin, verifySubscription, handlePlanChange } = useContext(AuthContext);
  const { cardList, editCard, editPlan } = useContext(PlanContext);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (!user.subscription) {
        if (!(paystackTrigger && reference)) return;
        if (reference === localStorage.getItem(Constants.StorageKey.TransactionReference)) return;

        try {
          setShowModal(true);
          localStorage.setItem(Constants.StorageKey.TransactionReference, reference);
          await verifySubscription(reference);
          await getAdmin();

          history.push(Constants.Links.Sidebar.Path.Home);
          setShowModal(false);
          return;
        } catch (error) {
          setShowModal(false);
          errorToast(error as string);
          return;
        }
      }

      const result = await cardList();
      if (!result || result.length === 0) return;

      const attributes = result.map(({ attributes }: CardType) => attributes);
      if (attributes) setCards(attributes);

      const defaultCard = attributes.find((card: CardAttributeType) => card.default);

      if (paystackTrigger) {
        const result = await editPlan(planId);
        if (result) handlePlanChange(result);
      }

      if (defaultCard) setCardId(defaultCard.id);
    })();
  }, [show, paystackTrigger]);

  const config: PaystackProps = {
    email: user.email,
    amount: 10000,
    metadata: {
      userType: user.is_admin ? Constants.UserType.Admin : Constants.UserType.Employee,
      additionalInfo: 'card',
      planId: planId
    } as any,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY as string
  };

  const initializePayment = usePaystackPayment(config);

  const onPaystackSuccess = (response: PaystackOnSuccessResponseType) => {
    setPaystackTrigger(false);
    setReference(response.reference);
    setPaystackTrigger(true);
  };

  const onPaystackClose = () => {
    console.log('closed');
  };

  const createCard = () => {
    closeModal();
    initializePayment(onPaystackSuccess, onPaystackClose);
  };

  const closeModal = () => {
    onClose();
  };

  const updatePlan = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if(cardId) await editCard(cardId);
    const result = await editPlan(planId);
    if (result) handlePlanChange(result);
    closeModal();

    setLoading(false);
  };

  return (
    <React.Fragment>
      <CModal
        title=" "
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <Loader />
      </CModal>
      <CModal
        show={show}
        title={user.subscription && 'Select a payment method'}
        onClose={closeModal}
        footer={
          user.subscription && <React.Fragment>
            <CButton loading={loading} onClick={updatePlan}>Ok</CButton>
          </React.Fragment>
        }
      >
        {user.subscription && <p className="mb-2">
          Please select a payment method or add a new one for your pricing plan
        </p>}

        {user.subscription && <CFormSelect
          placeholder="Select Payment Method"
          name="planCard"
          value={`${cardId}`}
          onInput={setCardId}
          options={cards}
          optionValue="id"
          optionName="formatted_last4"
        />}

        {cards.length < 4 && <p className="mt-3">
          <a className="link-text" onClick={createCard}>+ Add new payment method</a>
          <br />
          <i className="small">you will be refunded when you add a new card</i>
        </p>}
      </CModal>
    </React.Fragment>
  );
};

export default PaymentData;
