import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/useAuthContext';
import { DashboardContext } from '../contexts/useDashboardContext';
import {NavLink} from 'react-router-dom';
import {formatRatingScore, truncateString} from '../utils/stringManipulation';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Constants } from '../utils/constants';
import {isSubscriptionEnabled, getAvatar, logo} from '../utils/helpers';
import {sidebarLinks} from '../utils/routes';
import AlertComponent from './AlertComponent';
import { BusinessType } from '../utils/types/business_types';
import { ProfileType, UserAttributeType } from '../utils/types/auth_types';
import { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import starIcon from '../assets/images/star-icon.svg';
import Chip from './Chip';

const Sidebar = () => {
  const [currentAdmin, setCurrentAdmin] = useState<UserAttributeType>({} as UserAttributeType);
  const [currentBusinessId, setCurrentBusinessId] = useState('');
  const [loading, setLoading] = useState(false);
  const [businessDeactivationWarning, setBusinessDeactivationWarning] = useState(false);
  const [showDeactivationMessage, setShowDeactivationMessage] = useState(false);

  const {
    logout,
    isAdmin,
    filterLinksWithAccess,
    state: authState,
    setCurrentBusiness
  } = useContext(AuthContext);
  const { toggleSidebar, state: dashboardState } = useContext(DashboardContext);

  useEffect(() => {
    if (currentBusinessId === authState.currentBusinessId && !authState.updateSubscriptionData) return;

    (async () => {
      setLoading(true);

      if (isAdmin()) setCurrentAdmin(authState.user);

      setCurrentBusinessId(authState.currentBusinessId);

      const business = authState.currentBusiness;

      setBusinessDeactivationWarning(
        business.attributes.status === Constants.Statuses.Business.MarkedForDeactivation
      );
      setShowDeactivationMessage(business.attributes.status === Constants.Statuses.Business.Deactivated);
      setLoading(false);
    })();
  }, [authState.currentBusiness]);

  const getSidebarState = () => {
    return dashboardState.sidebarState;
  };

  const getSidebarIcon = (title: string) => {
    const name = title.replace(/\s/g, '-').toLowerCase();
    return require(`../assets/images/dashboard/${name}-icon.svg`);
  };

  const handleLogout = async (e: any) => {
    e.preventDefault();
    await logout();
  };

  const userAvatar = () => {
    const { profile = {} as ProfileType } = authState.user;
    const { attributes } = profile;
    return getAvatar(attributes);
  };

  const otherBusinesses = () => {
    return authState.user.businesses.filter((el) => el.id !== authState.currentBusiness.id);
  };

  const switchCurrentBusiness = (e: React.MouseEvent<DropdownItemProps>, business: BusinessType) => {
    e.preventDefault();
    setCurrentBusiness(authState.user.businesses, business);
  };

  const showSubscriptionDeactivationWarning = () => {
    return isSubscriptionEnabled &&
      authState.user.subscription?.attributes?.status === Constants.Statuses.Subscription.MarkedForCancellation;
  };

  const businessAlert = () => {
    if (loading) return;

    if (showDeactivationMessage) {
      return <AlertComponent
        text="This business has been deactivated"
        dismissible={false}
        type="danger"
      />;
    } else if (businessDeactivationWarning || showSubscriptionDeactivationWarning()) {
      return <AlertComponent
        text={
          <span>
              This business has been marked for deactivation.
            {businessDeactivationWarning && <span> You can upgrade your plan <a href={Constants.Links.Settings.Path.Plans} className="link-text">here.</a></span>}
          </span>
        }
        dismissible={false}
        type="warning"
      />;
    }
  };

  return (
    <React.Fragment>
      <aside className={`c-sidebar ${getSidebarState() ? 'show' : ''}`}>
        <div className={'c-sidebar__top'}>
          <div className="c-sidebar__logo">
            <img src={logo} alt="Logo" />
          </div>
          <nav className="c-sidebar__nav">
            <ul>
              {filterLinksWithAccess(sidebarLinks).map(({ title, path }, index) => (
                <li key={index}>
                  <NavLink
                    to={path}
                    exact={path === Constants.Links.Sidebar.Path.Home}
                    activeClassName="is-active"
                    className="c-sidebar__link"
                  >
                    <img src={getSidebarIcon(title)} alt={title} />
                    <span>{title}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        {businessAlert()}

        <div className="c-sidebar__bottom">
          <div className="c-sidebar__business">
            <div className="flex">
              <div className="mb-1 c-sidebar__business-row">
                <small>Business</small>
                <div className="d-flex">
                  <Chip>
                    <div className="d-flex align-items-baseline" style={{gap: '3px'}}>
                      <img src={starIcon} alt="business overall rating" className="c-sidebar__business-rating-icon" />
                      <span className="ps-1 small c-sidebar__business-rating">
                        {formatRatingScore(authState.currentBusiness.attributes.rating.score)}
                      </span>
                    </div>
                  </Chip>
                </div>
              </div>
              <h4 data-title={authState.currentBusiness.attributes.name}>
                {truncateString(authState.currentBusiness.attributes.name, 10)}
              </h4>
            </div>
            {isAdmin() && (
              <DropdownButton
                as="span"
                align="end"
                drop="up"
                title=""
                className="dropdown-icon"
              >

                {Object.keys(currentAdmin).length > 0 &&
                  <React.Fragment>
                    {currentAdmin.businesses.length < 3 &&
                      <Dropdown.Item href={Constants.Links.Sidebar.Path.AddBusiness}>
                        Add new business
                      </Dropdown.Item>
                    }

                    {currentAdmin.businesses.length > 1 &&
                      otherBusinesses().map((business, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            id={business.id}
                            onClick={(e: any) => switchCurrentBusiness(e, business)}
                          >
                            {business.attributes.name}
                          </Dropdown.Item>
                        );
                      })
                    }
                  </React.Fragment>
                }
              </DropdownButton>
            )}
          </div>
          <div className="c-sidebar__user">
            <div className="flex">
              <img src={userAvatar()} alt="User" className="c-sidebar__avatar" />
              <h5 data-title={authState.user.name}>
                {truncateString(authState.user.first_name, 7)}
              </h5>
            </div>
            <DropdownButton
              as="span"
              align="end"
              title=""
              className="dropdown-icon"
            >
              <Dropdown.Item href={Constants.Links.Settings.Path.Profile}>Profile</Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={handleLogout}>
                Sign out
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </aside>
      <div
        className={`c-sidebar__overlay ${getSidebarState() ? 'show' : ''}`}
        onClick={toggleSidebar}
      />
    </React.Fragment>
  );
};

export default Sidebar;
