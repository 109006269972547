import React from 'react';
import closeIcon from '../../assets/images/dashboard/close.svg';
import uploadIcon from '../../assets/images/dashboard/upload-icon.svg';
import loadingIcon from '../../assets/images/loading-primary.svg';
import { FileUploader } from 'react-drag-drop-files';
import {errorToast, logo} from '../../utils/helpers';

const fileTypes = ['JPG', 'JPEG', 'PNG'];
type ReferrerType = 'business-profile' | 'profile';

interface Props {
  image: string;
  loading?: boolean;
  onChange: (_: any) => void;
  onRemove?: (_: any) => void;
  referrer?: ReferrerType;
}

const CImageUpload = ({
  image,
  loading,
  onChange,
  onRemove,
  referrer = 'business-profile'
}: Props) => {
  const getUploadIcon = loading ? loadingIcon : uploadIcon;

  const checkType = (error: string) => {
    return errorToast(error);
  };

  const isBusinessProfile = referrer === 'business-profile';

  return (
    <div className="c-upload__box">
      <div className={`c-upload__image--wrapper ${image ? '' : 'default'}`}>
        <img
          className="c-upload__image"
          src={logo}
          alt={`${isBusinessProfile ? 'upload business images' : 'upload profile picture'}`}
        />
        {isBusinessProfile && <img
          className="c-upload__image--close"
          src={closeIcon}
          alt="close"
          onClick={onRemove}
        />}
      </div>
      <div className="c-upload__container">
        <div className={`c-upload__text ${loading ? 'loading' : ''}`}>
          <img
            className="c-upload__icon"
            src={getUploadIcon}
            alt="Upload image here"
          />
          <p className="c-upload__description">
            <span>{`Click here to ${image.length > 0 ? 'change' : 'add'}`}</span>
            <br />
          </p>
          <span>{`or Drag and drop your ${isBusinessProfile ? 'images' : 'profile picture'} here`}</span>
        </div>
        <FileUploader
          classes="c-upload"
          handleChange={onChange}
          name="file"
          disabled={loading}
          types={fileTypes}
          onTypeError={checkType}
        />
      </div>
    </div>
  );
};

export default CImageUpload;
