import React, {createContext, useReducer, useEffect, useContext, PropsWithChildren} from 'react';
import { Constants } from '../../../utils/constants';
import { AuthContext } from '../../../contexts/useAuthContext';
import {
  getCardList,
  updateCard,
  updatePlan,
  removeCard,
  createCard,
  findAccountName,
  getBankDetailList,
  createBankDetail,
  updateBankDetail,
  removeBankDetail,
  getPaymentList
} from '../../../utils/apiCalls/payment';
import { initializeState, updateContextState, handleError, successToast } from '../../../utils/helpers';
import {PlanContextActionType, PlanStateObjectType, PlanStateType} from '../../../utils/types/subscription_types';
import {ApiErrorType} from '../../../utils/types/util_types';

const initialState = {
  updateState: false,
  hasPayments: false,
  items: [],
  totalCount: 0,
  pageNumber: 1,
};

const PlanReducer = (state: PlanStateObjectType, action: PlanContextActionType): PlanStateObjectType => {
  switch (action.type) {
  case Constants.Actions.Plan.State:
    return { ...state, ...action.payload as PlanStateObjectType };
  case Constants.Actions.Plan.ResetUpdateState:
    return {
      ...state,
      updateState: action.payload as boolean
    };
  default:
    return { ...state };
  }
};

const actions = (dispatch: (_: any) => void) => {
  const { state: authState } = useContext(AuthContext);

  const resetUpdateState = (payload: boolean = true) => {
    dispatch({
      type: Constants.Actions.Plan.ResetUpdateState,
      payload
    });
  };

  const returnValue = {
    cardList: async () => {
      const { user } = authState;

      try {
        resetUpdateState();
        const cards = await getCardList(user);

        const { data } = cards;

        return data.data;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    editPlan: async (planId: number) => {
      const { user } = authState;

      try {
        resetUpdateState();
        const result = await updatePlan(user, planId);
        const {data} = result;

        return data.data;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    editCard: async (cardId: number) => {
      const { user } = authState;

      try {
        resetUpdateState();
        await updateCard(user, cardId);

        successToast('You have successfully changed your default card');

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    deleteCard: async (cardId: number) => {
      const { user } = authState;

      try {
        resetUpdateState();
        await removeCard(user, cardId);

        successToast('Successfully deleted card');

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    addCard: async (reference: string) => {
      const { user } = authState;

      try {
        resetUpdateState();
        await createCard(user, reference);
        successToast('Your card has been added successfully');
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    retrieveAccountName: async (accountNumber: string, bankCode: string) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const result = await findAccountName(user, currentBusinessId, accountNumber, bankCode);

        const { data } = result;

        return data;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    addBankDetail: async ({accountName, accountNumber, bankCode}: {
      accountName: string,
      accountNumber: string,
      bankCode: string
    }) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const result = await createBankDetail(user, currentBusinessId,{
          accountName,
          accountNumber,
          bankCode
        });

        const { data } = result;

        return data;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    bankDetailList: async () => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const bankDetails = await getBankDetailList(user, currentBusinessId);

        const { data } = bankDetails;

        return data.data;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    editBankDetail: async (bankDetailId: number) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        const result = await updateBankDetail(user, currentBusinessId, bankDetailId);

        const { data } = result;
        successToast(data.message);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    deleteBankDetail: async (bankDetailId: number) => {
      const { user, currentBusinessId } = authState;

      try {
        resetUpdateState();
        await removeBankDetail(user, currentBusinessId, bankDetailId);

        successToast('Successfully deleted card');

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    paymentList: async (pageNumber: number) => {
      const { user } = authState;

      try {
        resetUpdateState();
        const payments = await getPaymentList(user, pageNumber);

        const { data } = payments;

        const payload = {
          hasPayments: data.has_items,
          items: data.data,
          totalCount: data.metadata.total_count,
          pageNumber: data.metadata.current_page
        };

        dispatch({
          type: Constants.Actions.Plan.State,
          payload
        });

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    }
  };
  return returnValue;
};

type PlanContextActions = ReturnType<typeof actions>
type PlanContextType = PlanStateType & PlanContextActions
export const PlanContext = createContext<PlanContextType>({} as PlanContextType);

export default function PlanProvider({ children, value }: PropsWithChildren<{value?: PlanStateObjectType}>) {
  const [state, dispatch] = useReducer(
    PlanReducer,
    initializeState(Constants.StorageKey.Plan, { ...initialState, ...(value as PlanStateObjectType ?? {})})
  );

  useEffect(() => {
    if (state.updateState)
      updateContextState(Constants.StorageKey.Plan, state);
  }, [state]);

  return (
    <PlanContext.Provider value={{ state, ...actions(dispatch) }}>
      {children}
    </PlanContext.Provider>
  );
}
