import React from 'react';
import { Card } from 'react-bootstrap';
import SadSmileyIcon from '../assets/images/dashboard/sad-smiley.svg';
import SmileyIcon from '../assets/images/dashboard/smiley.svg';

interface Props {
  header: string,
  title: string,
  description: string,
  sad?: boolean
}

const EmptyCard = ({
  title,
  header,
  description,
  sad = true
}: Props) => {
  return (
    <React.Fragment>
      <Card.Body>
        <p className="text-dark">
          <strong>{header}</strong>
        </p>
        <div className="home-text">
          <img src={sad ? SadSmileyIcon : SmileyIcon} alt="Empty Icon" />
          <p className="home-text__title">{title}</p>
          <p className="home-text__desc">{description}</p>
        </div>
      </Card.Body>
    </React.Fragment>
  );
};

export default EmptyCard;
