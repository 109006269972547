import { deleteResource, getResource, postResource, putResource } from './index';
import { Constants } from '../constants';
import {UserAttributeType} from '../types/auth_types';

export const createCategory = async (
  user: UserAttributeType,
  currentBusinessId: string,
  categoryName: string
) => {
  return await postResource(
    `/v1/businesses/${currentBusinessId}/sub_categories`,
    { sub_category: { name: categoryName } },
    user.token
  );
};

export const getCategoryList = async (
  user: UserAttributeType,
  currentBusinessId: string,
  pageNumber = 1,
  q: string = '',
  fetchAll: boolean = false
) => {
  return await getResource(
    `/v1/businesses/${currentBusinessId}/sub_categories?page=${pageNumber}&per_page=${Constants.PerPageCount.Categories}&q=${q}&fetch_all=${fetchAll}`,
    user.token
  );
};

export const removeCategory = async (
  user: UserAttributeType,
  currentBusinessId: string,
  id: number
) => {
  return await deleteResource(
    `/v1/businesses/${currentBusinessId}/sub_categories/${id}`,
    user.token
  );
};

export const updateCategory = async (
  user: UserAttributeType,
  currentBusinessId: string,
  id: number,
  categoryName: string
) => {
  return await putResource(
    `/v1/businesses/${currentBusinessId}/sub_categories/${id}`,
    { sub_category: { name: categoryName } },
    user.token
  );
};
