import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import chevronLeftIcon from '../assets/images/dashboard/chevron-left.svg';
import {FilterItemType, OnSearchType} from '../utils/types/util_types';

const organizePageRanges = ({ pages, pageRange }: { pages: number | undefined, pageRange: number | undefined }) => {
  if (!pages || !pageRange) return [];

  const total = new Array(pages).fill(0);
  return total.reduce((acc, el, index) => {
    const start = pageRange * index + 1;
    const end = start + pageRange - 1;
    acc.push(`${start} - ${end}`);
    return acc;
  }, []);
};

interface Props {
  totalCount: number;
  onChange: OnSearchType;
  query?: string;
  filter?: FilterItemType;
  pageRange?: number;
  dashboard?: boolean;
}

const CPagination = ({
  totalCount,
  onChange,
  query = '',
  filter,
  pageRange = 5,
  dashboard = false 
}: Props) => {
  const [currentRange, setCurrentRange] = useState(`1 - ${pageRange}`);
  const [allRanges, setAllRanges] = useState([`1 - ${pageRange}`]);
  const [currentPage, setCurrentPage] = useState(1);
  const [triggerOnChange, setTriggerOnChange] = useState(false);

  useEffect(() => {
    const pages = Math.ceil(totalCount / pageRange);
    const ranges = organizePageRanges({ pages, pageRange });
    setAllRanges(ranges);
    setCurrentPage(1);
  }, [totalCount]);

  useEffect(() => {
    setCurrentRange(allRanges[currentPage - 1]);
  }, [currentPage]);

  useEffect(() => {
    if (!triggerOnChange) return;

    onChange(currentPage, query as string, filter);
    setTriggerOnChange(false);
  }, [triggerOnChange]);

  const handleChangePagination = (index: number) => {
    setCurrentPage(index + 1);
    setTriggerOnChange(true);
  };
  const isPrevDisabled = () => {
    return currentPage === 1 ? 'disabled' : '';
  };
  const isNextDisabled = () => {
    return currentPage === allRanges.length ? 'disabled' : '';
  };
  const handlePrevButton = () => {
    if (currentPage === 1) return;
    setCurrentPage(prev => (prev -= 1));
    setTriggerOnChange(true);
  };
  const handleNextButton = () => {
    if (currentPage === allRanges.length) return;
    setCurrentPage(prev => (prev += 1));
    setTriggerOnChange(true);
  };

  return (
    <section className={`c-pagination__${dashboard ? 'dashboard-' : ''}box`}>
      <DropdownButton
        as="span"
        align="end"
        drop="down"
        title={currentRange}
        className="c-pagination__pages"
      >
        {allRanges.map((range, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => handleChangePagination(index)}
          >
            {range}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <p className="c-pagination__meta">of {totalCount}</p>
      <ul className="c-pagination">
        <li className="c-pagination-item">
          <button
            className={`c-pagination-link ${isPrevDisabled()}`}
            onClick={handlePrevButton}
          >
            <img src={chevronLeftIcon} alt="previous" />
          </button>
        </li>
        <li className="c-pagination-item">
          <button
            className={`c-pagination-link ${isNextDisabled()}`}
            onClick={handleNextButton}
          >
            <img src={chevronLeftIcon} alt="previous" className="inverse" />
          </button>
        </li>
      </ul>
    </section>
  );
};

export default CPagination;
