import React, {useState, useContext, FormEvent} from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import forgotPasswordLogo from '../assets/images/forgot-password.svg';
import { AuthContext } from '../contexts/useAuthContext';
import CButton from '../shared/forms/Button';
import CCard from '../shared/CCard';
import { urlParams } from '../utils/helpers';
import AlertComponent from '../shared/AlertComponent';

const VerifyEmail = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { verify } = useContext(AuthContext);

  const handleEmailVerification = async (e: FormEvent<HTMLFormElement>) => {
    const confirmationToken = urlParams('confirmation_token') as string;

    setLoading(true);
    e.preventDefault();
    try {
      const result = await verify({ confirmationToken });

      setLoading(false);
      if (result) {
        window.location.href =
          'https://cornaio.page.link/email-verify-completed';
      }
    } catch (error) {
      setLoading(false);
      setError(error as string);
    }
  };

  return (
    <div className="c-auth__gradient">
      <CCard
        content={
          <div className="c-auth__content">
            <img src={forgotPasswordLogo} alt="Logo" />
            <br />
            <br />
            <h1>Verify Email</h1>
            {error ? (
              <React.Fragment>
                <br />
                <AlertComponent
                  text={error}
                  type="danger"
                  onClose={() => setError('')}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p className="mt-2">Click this button to verify your email.</p>
                <br />
                <Form onSubmit={handleEmailVerification}>
                  <Row className="align-items-center">
                    <Col md>
                      <CButton
                        color="primary"
                        type="submit"
                        loading={loading}
                        fullWidth
                      >
                        Verify email
                      </CButton>
                    </Col>
                  </Row>
                </Form>
              </React.Fragment>
            )}
          </div>
        }
      />
    </div>
  );
};

export default withRouter(VerifyEmail);
