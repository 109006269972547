import { Constants } from '../constants';
import { getContextState } from '../helpers';
import {
  deleteResource,
  getResource,
  postResource,
  putResource
} from './index';
import {ProfileFormAttributeType, UserFormAttributeType, UserAttributeType} from '../types/auth_types';
import {StaffAttributeType} from '../types/staff_types';


export const presetData = async () => {
  const preset_data = await getResource(
    '/v1/preset_data'
  );

  const { data } = preset_data.data;

  localStorage.setItem(Constants.StorageKey.PresetData, JSON.stringify(data));
  return data;
};

export const logoutUser = async () => {
  const user = getContextState(Constants.StorageKey.Session, Constants.StorageKey.User);

  if (user) {
    const type = user.is_admin ? Constants.UserType.Admin : Constants.UserType.Employee;
    await deleteResource(
      `/v1/${type.toLowerCase()}/logout?refresh_token=${user.refresh_token}`,
      user.token
    );
  }

  localStorage.clear();
};

export const loginAdmin = async ({ email, password }: {email: string, password: string}) => {
  const response = await postResource(
    '/v1/admin/login',
    { admin: { email, password } }
  );
  const { data } = response.data;
  return data.attributes;
};

export const loginEmployee = async ({ email, password }: {email: string, password: string}) => {
  const response = await postResource(
    '/v1/employee/login',
    { employee: { email, password } }
  );
  const { data } = response.data;
  return data.attributes;
};

export const registerUser = async ({
  email,
  password,
  agreement,
  firstName: first_name,
  lastName: last_name,
  businessName: name,
  businessSize: size,
  businessCategory: category_id
}: UserFormAttributeType) => {
  let user;

  const invitationData = JSON.parse(
    localStorage.getItem(Constants.StorageKey.Employee) as string
  );

  if (invitationData) {
    user = await putResource(
      `/v1/employees?invitation_token=${invitationData.invitation_token}`,
      {
        employee: {
          password,
          agreement
        }
      }
    );
  } else {
    user = await postResource(
      '/v1/admins',
      {
        admin: {
          email,
          password,
          first_name,
          last_name,
          agreement,
          businesses_attributes: [
            {
              name,
              category_id,
              size
            }
          ]
        }
      }
    );
  }

  const { data } = user.data;

  localStorage.removeItem(Constants.StorageKey.Employee);
  return data.attributes;
};

export const findEmployeeInvitation = async (invitationToken: string) => {
  const employee = await getResource(
    `/v1/employee/retrieve?invitation_token=${invitationToken}`
  );
  const { data } = employee.data;

  const employeeAttributes = JSON.stringify(data.attributes);

  localStorage.setItem(Constants.StorageKey.Employee, employeeAttributes);
};

export const findAdmin = async (user: UserAttributeType) => {
  const admin = await getResource(
    `/v1/admins/${user.id}`,
    user.token
  );
  const { data } = admin.data;

  return data.attributes;
};

export const updateAdmin = async (user: UserAttributeType, profileId: string, {
  firstName: first_name,
  lastName: last_name,
  phoneNumber: contact_number,
  currentPassword: current_password,
  password,
  email,
  gender,
  bookable,
  avatar
}: ProfileFormAttributeType & UserFormAttributeType) => {
  return await putResource(
    `/v1/admins/${user.id}`, {
      current_password,
      admin: {
        first_name,
        last_name,
        email,
        bookable,
        password,
        profile_attributes: {
          id: profileId,
          avatar,
          gender,
          contact_number
        }
      }
    },
    user.token
  );
};

export const updateEmployee = async (user: UserAttributeType, currentBusinessId: string, profileId: string, {
  firstName: first_name,
  lastName: last_name,
  phoneNumber: contact_number,
  currentPassword: current_password,
  password,
  email,
  gender,
  avatar
}: ProfileFormAttributeType & UserFormAttributeType) => {
  return await putResource(
    `/v1/businesses/${currentBusinessId}/employees/${user.id}`, {
      current_password,
      employee: {
        first_name,
        last_name,
        email,
        current_password,
        password,
        profile_attributes: {
          id: profileId,
          avatar,
          gender,
          contact_number
        }
      }
    },
    user.token
  );
};

export const findEmployee = async (
  staff: StaffAttributeType | UserAttributeType,
  businessId: string,
  user: UserAttributeType
) => {
  const employee = await getResource(
    `/v1/businesses/${businessId}/employees/${staff.id}`,
    user.token
  );

  const { data } = employee.data;

  return data.attributes;
};

export const verifyEmail = async (confirmationToken: string) => {
  await putResource(
    `/verify?confirmation_token=${confirmationToken}`
  );
};

export const getUserForgottenPassword = async ({ email }: {email: string}) => {
  await postResource(
    '/v1/password/forgot',
    { email }
  );
};

export const resetUserPassword = async ({ token, password }: {token: string, password: string}) => {
  await postResource(
    '/v1/password/reset',
    {
      token,
      password
    }
  );
};

export const accountDeactivation = async (admin: UserAttributeType) => {
  return await deleteResource(
    `/v1/admins/${admin.id}`,
    admin.token
  );
};

export const accountReactivation = async (admin: UserAttributeType) => {
  return await putResource(
    `/v1/admins/${admin.id}/reactivate`,
    {},
    admin.token
  );
};

export const retrieveSubscription = async (admin: UserAttributeType, reference: string) => {
  await postResource(
    '/v1/verify_transaction',
    { reference: reference },
    admin.token
  );
};

export const getRefreshToken = async () => {
  const user = getContextState(Constants.StorageKey.Session, Constants.StorageKey.User);
  const response = await postResource(
    `${process.env.REACT_APP_ENDPOINT_URL}/v1/refresh_token`,
    { refresh_token: user.refresh_token },
    user.token
  );

  return response.data;
};
