import AWS from 'aws-sdk';
import {imageFileName} from '../stringManipulation';
import {UploadUrlType} from '../types/util_types';

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME;
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY
});

const s3 = new AWS.S3({ region: REGION });
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
});

export const getUploadUrl = (value: UploadUrlType, file: File, imagePosition?: number) => {
  return new Promise((resolve, reject) => {
    const fileName = imageFileName(value, imagePosition);

    const params = {
      Bucket: S3_BUCKET,
      Key: fileName,
      ContentType: file.type,
      ACL: 'public-read',
    };

    myBucket.getSignedUrl('putObject', params, function (err, url) {
      if (err) {
        reject(err);
      }
      resolve(url);
    });
  });
};

export const getRemoteImageUrl = (key: string) => {
  return myBucket.getSignedUrl('getObject', {
    Bucket: S3_BUCKET,
    Key: key
  });
};

export const deleteImage = (key: string) => {
  return s3.deleteObject({
    Bucket: S3_BUCKET as string,
    Key: key,
  }, function (err, data){
    console.log('err', err);
    console.log('data', data);
  });
};
