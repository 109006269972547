import {
  BookingDetails,
  Bookings,
  Home,
  Revenue,
  Services,
  ServiceData
} from '../dashboard';
import Login from '../auth/Login';
import Register from '../auth/Register';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';
import VerifyEmail from '../auth/VerifyEmail';
import ForgotPasswordSuccess from '../auth/ForgotPasswordSuccess';
import ResetPasswordSuccess from '../auth/ResetPasswordSuccess';
import SelectPlan from '../auth/SelectPlan';
import withSidebar from '../hoc/withSidebar';
import withSettingsLayout from '../hoc/withSettingsLayout';
import withAnalyticsLayout from '../hoc/withAnalyticsLayout';
import withServicesProvider from '../hoc/withServicesProvider';
import withRevenueProvider from '../hoc/withRevenueProvider';
import withBookingsProvider from '../hoc/withBookingsProvider';
import SettingsStaff from '../dashboard/settings/staff';
import SettingsProfile from '../dashboard/settings/profile';
import SettingsPlans from '../dashboard/settings/plans';
import SettingsPayments from '../dashboard/settings/payments';
import SettingsBusinessProfile from '../dashboard/settings/business-profile';
import SettingsSubscription from '../dashboard/settings/subscription';
import { Constants } from './constants';
import Analytics from '../dashboard/analytics/overview';
import CustomerAnalyticsDetails from '../dashboard/analytics/customers';
import { isSubscriptionEnabled } from './helpers';

export const analyticsPages = [
  {
    title: Constants.Links.Analytics.Title.Overview,
    component: withAnalyticsLayout(Analytics),
    path: Constants.Links.Analytics.Path.Overview,
    access: Constants.Access.Admin
  },
  {
    title: Constants.Links.Analytics.Title.CustomerDetails,
    component: withAnalyticsLayout(CustomerAnalyticsDetails),
    path: Constants.Links.Analytics.Path.CustomerDetails,
    access: Constants.Access.All
  }
];

export const authPages = [
  {
    title: Constants.Links.Auth.Title.Login,
    component: Login,
    path: Constants.Links.Auth.Path.Login
  },
  {
    title: Constants.Links.Auth.Title.Register,
    component: Register,
    path: Constants.Links.Auth.Path.Register
  },
  {
    title: Constants.Links.Auth.Title.ForgotPassword,
    component: ForgotPassword,
    path: Constants.Links.Auth.Path.ForgotPassword
  },
  {
    title: Constants.Links.Auth.Title.ForgotPasswordSuccess,
    component: ForgotPasswordSuccess,
    path: Constants.Links.Auth.Path.ForgotPasswordSuccess
  },
  {
    title: Constants.Links.Auth.Title.SelectPlan,
    component: SelectPlan,
    path: Constants.Links.Auth.Path.SelectPlan
  },
  {
    title: Constants.Links.Auth.Title.ResetPassword,
    component: ResetPassword,
    path: Constants.Links.Auth.Path.ResetPassword
  },
  {
    title: Constants.Links.Auth.Title.ResetPasswordSuccess,
    component: ResetPasswordSuccess,
    path: Constants.Links.Auth.Path.ResetPasswordSuccess
  },
  {
    title: Constants.Links.Auth.Title.VerifyEmail,
    component: VerifyEmail,
    path: Constants.Links.Auth.Path.VerifyEmail
  }
];

export const bookingsIndexPages = [
  {
    title: Constants.Links.Booking.Title.Confirmed,
    component: withSidebar(Bookings),
    path: Constants.Links.Booking.Path.Confirmed,
    access: Constants.Access.All
  },
  {
    title: Constants.Links.Booking.Title.Finished,
    component: withSidebar(Bookings),
    path: Constants.Links.Booking.Path.Finished,
    access: Constants.Access.All
  },
  {
    title: Constants.Links.Booking.Title.Canceled,
    component: withSidebar(Bookings),
    path: Constants.Links.Booking.Path.Canceled,
    access: Constants.Access.All
  },
  {
    title: Constants.Links.Booking.Title.Unconfirmed,
    component: withSidebar(Bookings),
    path: Constants.Links.Booking.Path.Unconfirmed,
    access: Constants.Access.NotBasicAccount
  }
];

export const servicePages = [
  {
    title: Constants.Links.Service.Title.CreateService,
    component: withServicesProvider(ServiceData),
    path: Constants.Links.Service.Path.CreateService,
    access: Constants.Access.Admin
  },
  {
    title: Constants.Links.Service.Title.EditService,
    component: withServicesProvider(ServiceData),
    path: Constants.Links.Service.Path.EditService,
    access: Constants.Access.Admin
  }
];

export const settingsPages = [
  {
    title: Constants.Links.Sidebar.Title.Settings,
    component: withSettingsLayout(SettingsStaff),
    path: Constants.Links.Sidebar.Path.Settings,
    access: Constants.Access.All
  },
  {
    title: Constants.Links.Settings.Title.Plans,
    component: withSettingsLayout(SettingsPlans),
    path: Constants.Links.Settings.Path.Plans,
    access: isSubscriptionEnabled
      ? Constants.Access.Admin
      : Constants.Access.Hide,
  },
  {
    title: Constants.Links.Settings.Title.Payments,
    component: withSettingsLayout(SettingsPayments),
    path: Constants.Links.Settings.Path.Payments,
    access: Constants.Access.Admin
  },
  {
    title: Constants.Links.Settings.Title.Subscription,
    component: withSettingsLayout(SettingsSubscription),
    path: Constants.Links.Settings.Path.Subscription,
    access: isSubscriptionEnabled
      ? Constants.Access.Admin
      : Constants.Access.Hide,
  },
  {
    title: Constants.Links.Settings.Title.BusinessProfile,
    component: withSettingsLayout(SettingsBusinessProfile),
    path: Constants.Links.Settings.Path.BusinessProfile,
    access: Constants.Access.Admin
  },
  {
    title: Constants.Links.Settings.Title.Profile,
    component: withSettingsLayout(SettingsProfile),
    path: Constants.Links.Settings.Path.Profile,
    access: Constants.Access.All
  }
];

export const sidebarLinks = [
  {
    title: Constants.Links.Sidebar.Title.Home,
    component: withSidebar(Home),
    path: Constants.Links.Sidebar.Path.Home,
    access: Constants.Access.All
  },
  {
    title: Constants.Links.Sidebar.Title.Bookings,
    component: withSidebar(Bookings),
    path: Constants.Links.Sidebar.Path.Bookings,
    access: Constants.Access.All
  },
  {
    title: Constants.Links.Sidebar.Title.Services,
    component: withServicesProvider(Services),
    path: Constants.Links.Sidebar.Path.Services,
    access: Constants.Access.All
  },
  {
    title: Constants.Links.Sidebar.Title.Revenue,
    component: withRevenueProvider(Revenue),
    path: Constants.Links.Sidebar.Path.Revenue,
    access: Constants.Access.Admin
  },
  {
    title: Constants.Links.Sidebar.Title.Analytics,
    component: withAnalyticsLayout(Analytics),
    path: Constants.Links.Sidebar.Path.Analytics,
    access: Constants.Access.Admin
  },
  {
    title: Constants.Links.Sidebar.Title.Analytics,
    component: withAnalyticsLayout(Analytics),
    path: Constants.Links.Sidebar.Path.CustomerDetails,
    access: Constants.Access.Staff
  },
  {
    title: Constants.Links.Sidebar.Title.Settings,
    component: withSettingsLayout(SettingsStaff),
    path: Constants.Links.Sidebar.Path.Settings,
    access: Constants.Access.All
  }
];

export const pages = [
  ...analyticsPages,
  ...bookingsIndexPages,
  ...servicePages,
  ...settingsPages,
  ...sidebarLinks,
  {
    title: Constants.Links.Booking.Title.ViewBooking,
    component: withBookingsProvider(BookingDetails),
    path: Constants.Links.Booking.Path.ViewBooking,
    access: Constants.Access.All
  }
];
