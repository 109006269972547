import {
  getResource,
  deleteResource,
  postResource,
  putResource
} from './index';
import { Constants } from '../constants';
import {UserAttributeType} from '../types/auth_types';
import {ServiceMutationAttribute} from '../types/service_types';
import {SelectOptionType} from '../types/util_types';

export const createService = async (
  user: UserAttributeType,
  currentBusinessId: string,
  {
    name,
    description,
    price,
    duration,
    subCategoryId: sub_category_id,
    status,
    attendantList = []
  }: ServiceMutationAttribute
) => {
  const serviceEmployeeIds = attendantList
    .filter(value => {
      return (value as string).split('-')[0] === 'employee';
    })
    .map(value => ({ employee_id: (value as string).split('-')[1] }));

  return await postResource(
    `/v1/businesses/${currentBusinessId}/services`,
    {
      service: {
        name,
        description,
        price,
        duration,
        sub_category_id,
        enable_admin_attendant: attendantList.join().indexOf('admin') > -1,
        status,
        service_employees_attributes: serviceEmployeeIds
      }
    },
    user.token
  );
};

export const updateService = async (
  id: string,
  user: UserAttributeType,
  currentBusinessId: string,
  {
    name,
    description,
    price,
    duration,
    subCategoryId: sub_category_id,
    status,
    attendantList = []
  }: ServiceMutationAttribute
) => {
  const serviceEmployeeIds = attendantList
    .filter(value => {
      if ((value as SelectOptionType).value) {
        return ((value as SelectOptionType).value as string).split('-')[0] === 'employee';
      } else {
        return (value as string).split('-')[0] === 'employee';
      }
    })
    .map(value => {
      if ((value as SelectOptionType).value) {
        return { employee_id: ((value as SelectOptionType).value as string).split('-')[1] };
      } else {
        return { employee_id: (value as string).split('-')[1] };
      }
    });

  const enableAdminAttendant = !!(typeof attendantList[0] === 'string' ?
    attendantList.find(attendant => (attendant as string)?.includes('admin')) :
    attendantList.find(attendant => ((attendant as SelectOptionType).value as string)?.includes('admin'))) || false;

  return await putResource(
    `/v1/businesses/${currentBusinessId}/services/${id}`,
    {
      service: {
        name,
        description,
        price,
        duration,
        sub_category_id,
        enable_admin_attendant: enableAdminAttendant,
        status,
        service_employees_attributes: serviceEmployeeIds
      }
    },
    user.token
  );
};

export const getServiceList = async (
  user: UserAttributeType,
  currentBusinessId: string,
  pageNumber: number = 1,
  q: string = '',
  status: string = '',
  subCategoryId: string = ''
) => {
  return await getResource(
    `/v1/businesses/${currentBusinessId}/services?page=${pageNumber}&per_page=${Constants.PerPageCount.Services}&q=${q}&status=${status}&sub_category_id=${subCategoryId}`,
    user.token
  );
};

export const findService = async (user: UserAttributeType, currentBusinessId: string, id: string) => {
  return await getResource(
    `/v1/businesses/${currentBusinessId}/services/${id}`,
    user.token
  );
};

export const removeService = async (user: UserAttributeType, currentBusinessId: string, id: string) => {
  return await deleteResource(
    `/v1/businesses/${currentBusinessId}/services/${id}`,
    user.token
  );
};
