import React, {useCallback, useContext} from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';
import { DashboardProvider } from '../contexts/useDashboardContext';
import { AuthContext } from '../contexts/useAuthContext';
import { Constants } from '../utils/constants';
import { errorToast } from '../utils/helpers';

interface IProps extends RouteProps {
  component: any;
}

export const AdminRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
  const { state: authState, isAdmin } = useContext(AuthContext);

  const dashboardProvider = useCallback((props: RouteProps) => {
    return <DashboardProvider routerProps={props}>
      {isAdmin() ? (
        <Component {...props} />
      ) : (
        <React.Fragment>
          <>
            {errorToast('You are not authorized to perform this action')}
            <Redirect to={Constants.Links.Sidebar.Path.Home} />
          </>
        </React.Fragment>
      )}
    </DashboardProvider>;
  },[authState.user && isAdmin()]);

  const setRoute = (props: RouteProps) => {
    if (authState.user) {
      return dashboardProvider(props);
    } else {
      return <Redirect to={Constants.Links.Auth.Path.Login} />;
    }
  };

  return (
    <Route
      {...rest}
      render={props => setRoute(props)}
    />
  );
};
