import React, { useContext, useEffect, useState } from 'react';
import BaseHeader from '../../shared/Header';
import BookingCalendar from './BookingCalendar';
import BookingTable from './BookingTable';
import { Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { AuthContext } from '../../contexts/useAuthContext';
import { bookingsIndexPages } from '../../utils/routes';
import { NavLink } from 'react-router-dom';
import {numericDate} from '../../utils/stringManipulation';
import CollapseCaret from '../../assets/images/caret-collapse.svg';
import ExpandCaret from '../../assets/images/caret-expand.svg';
import { Constants } from '../../utils/constants';

interface ContextAwareToggleProps {
  eventKey: string;
  callback?: (_: any) => void;
  children: string;
}
const ContextAwareToggle = ({ eventKey, callback }: ContextAwareToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => {
      if (callback) callback(eventKey);
    }
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      className="color-primary"
    >
      {isCurrentEventKey ? 'Hide bookings' : 'View bookings'}
      <img alt="caret" src={isCurrentEventKey ? CollapseCaret : ExpandCaret} className="ps-2" />
    </button>
  );
};

const Bookings = () => {
  const [formattedDates, setFormattedDates] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState([]);
  const [currentIndex, setCurrentIndex] = useState('0');
  const [queryString, setQueryString] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>(Constants.Links.Booking.Path.Confirmed);

  const {state: authState, filterLinksWithSubscription} = useContext(AuthContext);

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (queryString) {
      params.delete('from');
      params.delete('to');
      params.delete('status');
    }

    let from = params.get('from');
    let to = params.get('to');

    if (from && to) {
      from = from.replace(/-/g, '/');
      to = to.replace(/-/g, '/');
    }

    const fromDate = from || formattedDates[0];
    const toDate = to || formattedDates[1];

    if (!fromDate || !toDate) return;

    const range = dateList(
      convertDateToUTC(new Date(fromDate)),
      convertDateToUTC(new Date(toDate))
    );

    setDateRange(range);
  }, [JSON.stringify(formattedDates)]);

  const convertDateToUTC = (date: Date) => {
    return new Date(date.toUTCString().slice(0, -4));
  };

  const timezone = authState.currentBusiness.attributes.timezone;

  const onDateChange = (dates: Date[], resetQueryString?: boolean) => {
    setFormattedDates(dates.map((date) => numericDate(date.toLocaleDateString(), timezone)));
    if (resetQueryString) setQueryString(resetQueryString);
  };

  const addDays = (date: Date, count: number = 1) => {
    const result = new Date(date);
    result.setDate(result.getDate() + count);
    return result;
  };

  const dateList: any = (start: Date, end: Date, range: Date[] = []) => {
    if (start > end) return range;
    const next = addDays(start, 1);
    return dateList(next, end, [...range, start]);
  };

  return (
    <Accordion defaultActiveKey="0" onSelect={(idx: any) => setCurrentIndex(idx as string)}>
      <BaseHeader title="Bookings" />
      <Row className="align-items-baseline">
        <Col md={3} className="mb-3 mb-md-0">
          <BookingCalendar onDateChange={onDateChange} />
        </Col>
        <Col md={9} className="mb-4 mb-md-0">
          {dateRange.map((date, index) => (
            <div key={index}>
              <div className={`d-flex ${index > 0 ? 'mt-3' : ''}`}>
                <h4 className="pe-4">{(date as Date).toDateString()}</h4>
                <ContextAwareToggle eventKey={`${index}`}>
                  View more
                </ContextAwareToggle>
              </div>

              <Accordion.Collapse eventKey={`${index}`}>
                <React.Fragment>
                  <nav className="settings-tab__nav">
                    <ul className="settings-tab__list">
                      {filterLinksWithSubscription(bookingsIndexPages).map(({ title, path }) => {
                        return (
                          <li key={title} className="settings-tab__item">
                            <NavLink
                              onClick={() => setSelectedTab(path)}
                              exact
                              to={path}
                              isActive={() => selectedTab === path}
                              className="settings-tab__link"
                            >
                              {title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                  <BookingTable
                    date={numericDate(date, timezone)}
                    open={index === Number(currentIndex)}
                  />
                </React.Fragment>
              </Accordion.Collapse>
            </div>
          ))}
        </Col>
      </Row>
    </Accordion>
  );
};

export default Bookings;
