import React, {ReactElement, ReactNode} from 'react';
import {Modal} from 'react-bootstrap';

interface Props {
  title?: string | null;
  footer?: ReactElement | null;
  children?: ReactNode;
  show: boolean;
  onClose: () => void;
}

const CModal = ({ title, footer, children, show, onClose }: Props) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header>
        <h5 className="c-modal__title">{title}</h5>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {footer && <Modal.Footer className="c-modal__footer">{footer}</Modal.Footer>}
    </Modal>
  );
};
export default CModal;
