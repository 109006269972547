import React, {ReactNode, useContext, useEffect, useState} from 'react';
import CInlineFilter from './CInlineFilter';
import CInlineSearch from './CInlineSearch';
import CPagination from './CPagination';
import { debounce } from '../utils/formHelpers';
import { useHistory } from 'react-router-dom';
import Loader from './Loader';
import { Constants } from '../utils/constants';
import {monthDayYear} from '../utils/stringManipulation';
import {FilterItemType, OnSearchType} from '../utils/types/util_types';
import {AuthContext} from '../contexts/useAuthContext';

const CTableHead = ({ theadData }: { theadData: string[] }) => {
  return (
    <thead>
      <tr>
        {theadData.map((name, index) => (
          <th className="c-table__header" key={index}>
            {name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

interface CTableRowProps {
  children: ReactNode;
  clickable?: boolean;
  href?: string;
}

const CTableRow = ({ children, clickable = false, href }: CTableRowProps) => {
  const history = useHistory();

  const handleClick = () => {
    if (!clickable) return;

    if (href) history.push(href);
  };

  return (
    <tr
      className={`c-table__row ${clickable ? 'c-table__clickable-row' : ''}`}
      onClick={handleClick}>
      {children}
    </tr>
  );
};

interface CTableDataProps {
  styles?: object;
  classes?: string;
  children?: ReactNode;
}

const CTableData = ({ styles, classes = '', children }: CTableDataProps) => {
  return (
    <td className={`c-table__data ${classes}`} style={{ ...styles }}>
      {children}
    </td>
  );
};

interface CTableProps {
  children: ReactNode;
  theadData?: string[];
  title: string;
  dateFilterDefaultOption?: string;
  totalCount: number;
  onPageChange: OnSearchType;
  pageRange: number;
  showSearchOption?: boolean;
  filterItems?: FilterItemType[];
  OnSearch?: OnSearchType;
}

const CTable = ({
  children,
  theadData,
  title,
  dateFilterDefaultOption,
  totalCount = 0,
  onPageChange,
  pageRange = 5,
  showSearchOption = true,
  filterItems,
  OnSearch
}: CTableProps) => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({});
  const [showFilterClearButton, setShowFilterClearButton] = useState(false);
  const [showCustomDateFilter, setShowCustomDateFilter] = useState(false);
  const [dateFilterChange, setDateFilterChange] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [showCalendarIcon, setShowCalendarIcon] = useState(false);
  const [isDateFilter, setIsDateFilter] = useState(false);

  const { state: authState } = useContext(AuthContext);

  useEffect(() => {
    setSearch(search);
    setFilter(filter);
  }, [search, filter]);

  const handleInputChange = debounce((value: string) => {
    if (OnSearch) {
      OnSearch(1, value);
      setSearch(value);
    }
  }, 700);

  const timezone = authState.currentBusiness.attributes.timezone;

  const handleFilterChange = (obj: FilterItemType) => {
    const isCalendar = !!isCustomDateFilter(obj);

    setShowCustomDateFilter(isCalendar);
    setShowCalendarIcon(isCalendar);
    setIsDateFilter(isCalendar);

    OnSearch?.(1, '', obj);
    setShowFilterClearButton(true);
    setFilter(obj);
    setDateFilterChange('');

    if (obj.date) {
      setDateFilterChange(
        `${monthDayYear(`${obj.date.from}`, timezone)} - ${monthDayYear(`${obj.date.to}`, timezone)}`
      );
      return;
    }

    if (Object.keys(obj).length) {
      setFilterQuery(`${Object.keys(obj)[0]}: ${(Object.values(obj)[0] as string).toLowerCase()}`);
    }
  };

  const dateRange = () => {
    return dateFilterChange || dateFilterDefaultOption;
  };

  const resetFilters = () => {
    handleInputChange('');
    handleFilterChange({} as FilterItemType);
    setShowCustomDateFilter(false);
    setShowFilterClearButton(false);
    setShowCalendarIcon(false);
    setIsDateFilter(false);
  };

  const isCustomDateFilter = (f: FilterItemType) => {
    return Object.keys(f).includes(Constants.Filter.Date.Value.toLowerCase()) && f.date?.custom;
  };

  const toggleCalendar = () => {
    setShowCustomDateFilter(!showCustomDateFilter);
    setShowCalendarIcon(!showCalendarIcon);
  };

  return (
    <div className="c-table__box">
      <div>
        <section className="c-table__title">
          <p className="text-dark">
            <strong>
              {title} {totalCount > 0 && `- ${totalCount}`}
            </strong>
            {dateRange() && <small className="ms-2 color-gray">{dateRange()}</small>}
          </p>
          <div className="c-table__filters">
            {showSearchOption && <CInlineSearch value={search} onInput={handleInputChange} />}
            {filterItems && (
              <CInlineFilter
                values={filterItems}
                onChange={handleFilterChange}
                showCalendar={showCustomDateFilter}
                isDateFilter={isDateFilter}
                showCalendarIcon={showCalendarIcon}
                onToggle={() => toggleCalendar()}
              />
            )}
          </div>
        </section>
        {showFilterClearButton && (
          <div className="c-table__filter-results">
            <span>
              <span className="small color-primary">
                {dateFilterChange || filterQuery}
              </span>
              <button
                type="button"
                className="close ps-3"
                onClick={() => resetFilters()}
                title="Clear filter"
              >
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close alert</span>
              </button>
            </span>
          </div>
        )}
        <table className="c-table">
          <CTableHead theadData={theadData as string[]} />
          <tbody>
            {totalCount > 0 ? children : (
              <CTableRow>
                <CTableData>
                  {search.length > 0 || Object.keys(filter).length > 0 || dateFilterDefaultOption ?
                    <span>
                      No matches. <a className="link-text" onClick={() => resetFilters()}>Reset query</a>
                    </span> :
                    <Loader />}
                </CTableData>
                <CTableData />
              </CTableRow>
            )}
          </tbody>
        </table>
      </div>
      {totalCount > pageRange && <CPagination
        totalCount={totalCount}
        pageRange={pageRange}
        onChange={onPageChange}
        query={search}
        filter={filter as FilterItemType}
      />}
    </div>
  );
};

export { CTable, CTableHead, CTableRow, CTableData };
