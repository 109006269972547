import React, {useContext, useEffect, useState} from 'react';
import CModal from '../../../shared/forms/Modal';
import CButton from '../../../shared/forms/Button';
import {AnalyticsCustomerStateObjectType, RatingDataType} from '../../../utils/types/analytics_types';
import {AnalyticsContext} from '../useAnalyticsContext';
import {CTableRow} from '../../../shared/CTable';
import {CDashboardTable, CDashboardTableData} from '../../../shared/CDashboardTable';
import {formatRatingScore, pluralize} from '../../../utils/stringManipulation';
import {AnalyticsCustomerAttributeType} from '../../../utils/types/analytics_types';
import {Constants} from '../../../utils/constants';
import starIcon from '../../../assets/images/star-icon.svg';

interface Props {
  show: boolean;
  onClose: () => void;
  attributes: AnalyticsCustomerAttributeType;
}

const CustomerRatings = ({ show, onClose, attributes }: Props) => {
  const { state, customerRatingList } = useContext(AnalyticsContext);

  const [ratingConfig, setRatingConfig] = useState<RatingDataType>(state.customers.ratings ?? {});

  useEffect(() => {
    (async () => {
      const { customers = {} as AnalyticsCustomerStateObjectType } = state;
      const { ratings = {} as RatingDataType } = customers;
      const { items = [], totalCount = 0, pageNumber = 1 } = ratings;

      setRatingConfig({ items, totalCount, pageNumber } as RatingDataType);
    })();
  }, [show, state.customers.ratings]);

  const tableConfig = {
    title: `${formatRatingScore(attributes.rating.score)} • ${pluralize(attributes.rating.count, 'rating')}`,
    totalCount: ratingConfig.totalCount,
    pageRange: Constants.PerPageCount.CustomerRatings,
    onPageChange: async (page: number) => {
      if (ratingConfig.totalCount > 0) await fetchData(page);
    }
  };

  const closeModal = () => {
    onClose();
  };

  const fetchData = async (pageNumber = 1) => {
    await customerRatingList(attributes.id, pageNumber);
  };

  return (
    <CModal
      show={show}
      onClose={closeModal}
      footer={
        <CButton
          onClick={closeModal}
          small
        >
          Ok
        </CButton>
      }
    >
      <CDashboardTable {...tableConfig}>
        {(ratingConfig.items ?? []).map(({attributes}, index) => (
          <CTableRow key={index}>
            <CDashboardTableData>
              <div className="d-flex align-items-center">
                <img src={starIcon}  alt="customer's rating" className="analytics-customer-rating" />
                <strong className="ps-1">{formatRatingScore(attributes.customer_score)}</strong>
              </div>
            </CDashboardTableData>
            <CDashboardTableData>{attributes.customer_note}</CDashboardTableData>
          </CTableRow>
        ))}
      </CDashboardTable>
    </CModal>
  );
};

export default CustomerRatings;
