import React, {FormEvent, useContext, useEffect, useState} from 'react';
import { Breadcrumb, Col, Form, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import CButton from '../../shared/forms/Button';
import CFormInput from '../../shared/forms/Input';
import CMultiSelect from '../../shared/forms/MultiSelect';
import CFormSelect from '../../shared/forms/Select';
import CFormTextarea from '../../shared/forms/Textarea';
import BaseHeader from '../../shared/Header';
import CPageCard from '../../shared/PageCard';
import { StaffContext } from '../settings/staff/useStaffContext';
import { ServiceContext } from './useServiceContext';
import {AuthContext} from '../../contexts/useAuthContext';
import { setFormValue, getFormValue } from '../../utils/formHelpers';
import CSlideCheckbox from '../../shared/forms/SlideCheckbox';
import { getObjectId } from '../../utils/helpers';
import {convertTime, pluralize, titleize} from '../../utils/stringManipulation';
import { Constants } from '../../utils/constants';
import {SubCategoryType} from '../../utils/types/service_types';
import {StaffType} from '../../utils/types/staff_types';
import OnboardingTaskList from '../../shared/OnboardingTaskList';
import CConfirmationModal from '../../shared/forms/ConfirmationModal';
import {FilterItemType} from '../../utils/types/util_types';
import Loader from '../../shared/Loader';
import CModal from '../../shared/forms/Modal';
import {AdminAttributeType} from '../../utils/types/auth_types';

const getPageTitle = () => {
  const serviceId = getObjectId();

  if (serviceId === 'add') return 'Add New Service';
  return 'Edit Service';
};

const ServiceBreadCrumb = () => {
  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/services' }}>
          Services
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="capitalize">
          {getPageTitle()}
        </Breadcrumb.Item>
      </Breadcrumb>

      <h4 className="c-header__title">{getPageTitle()}</h4>
    </React.Fragment>
  );
};

type FormErrorKey =
  'attendants' |
  'category' |
  'description' |
  'duration' |
  'hours' |
  'minutes' |
  'name' |
  'price' |
  'status' |
  'sub_category';
type FormErrorType = Record<FormErrorKey, string[]>

type AttendantListType = {
  value: string,
  label: string
}

type ReferrerType = 'admin_profile' | 'invite_staff';

const ServiceData = () => {
  const [serviceForm, setServiceForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [hideHourError, setHideHourError] = useState(true);
  const [hideMinuteError, setHideMinuteError] = useState(true);
  const [status, setStatus] = useState('');
  const [formErrors, setFormErrors] = useState<Partial<FormErrorType>>({});
  const [categories, setCategories] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [attendants, setAttendants] = useState<AttendantListType[]>([]);
  const [staff, setStaff] = useState<AttendantListType[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDraftWarningModal, setShowDraftWarningModal] = useState(false);
  const [route, setRoute] = useState('');
  const [adminBookable, setAdminBookable] = useState(false);
  const [confirmedBookingCount, setConfirmedBookingCount] = useState(0);

  const { state: authState } = useContext(AuthContext);
  const { addService, categoryList, editService, getService } = useContext(
    ServiceContext
  );
  const { staffList } = useContext(StaffContext);

  const history = useHistory();
  const serviceId = getObjectId();

  useEffect(() => {
    (async () => {
      if (serviceId === 'add') return;

      setFormLoading(true);
      const service = await getService(serviceId as string);

      if (service) {
        const duration = convertTime(service.attributes.duration);

        setFormValue(setServiceForm, 'name', service.attributes.name);
        setFormValue(
          setServiceForm,
          'category',
          service.attributes.sub_category.attributes.id
        );
        setFormValue(
          setServiceForm,
          'description',
          service.attributes.description
        );

        setFormValue(setServiceForm, 'price', service.attributes.price_raw / 100);
        setFormValue(setServiceForm, 'hours', duration.hourCount);
        setFormValue(setServiceForm, 'minutes', duration.minuteCount);
        setFormValue(setServiceForm, 'attendants', service.attributes.attendants);
        setDefaultValues(service.attributes.attendants);
        setStatus(service.attributes.status);
        setConfirmedBookingCount(service.attributes.confirmed_booking_count);
      }
      setFormLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setAdminBookable((authState.user as AdminAttributeType).bookable ?? false);

      const categoryValues = await categoryList(1, '', true);
      if (!categoryValues) return;

      const categoryListing = categoryValues.map(({ attributes }: SubCategoryType) => attributes);
      setCategories(categoryListing);

      const attendants = await staffList(1, '', {} as FilterItemType, true);
      if (Object.keys(attendants).length === 0) return;

      const attendantList = (attendants as StaffType[]).map(attendant => ({
        value:
          attendant.type === 'employee'
            ? `employee-${attendant.attributes.id}`
            : `admin-${attendant.attributes.id}`,
        label: attendant.attributes.name
      }));

      const staffListing = (attendants as StaffType[]).filter(attendant => {
        return attendant.type === 'employee';
      }).map(attendant => ({
        value:
          attendant.type === 'employee'
            ? `employee-${attendant.attributes.id}`
            : `admin-${attendant.attributes.id}`,
        label: attendant.attributes.name
      }));
      setAttendants(attendantList);
      setStaff(staffListing);
    })();
  }, []);

  useEffect(() => {
    if (serviceId === 'add') {
      setFormValue(setServiceForm, 'hours', 1);
      setFormValue(setServiceForm, 'minutes', 30);
    }
  }, []);

  const isFormEmpty =
    getFormValue(serviceForm, 'hours') &&
    getFormValue(serviceForm, 'minutes') &&
    !getFormValue(serviceForm, 'name') &&
    !getFormValue(serviceForm, 'category') &&
    !getFormValue(serviceForm, 'price') &&
    !getFormValue(serviceForm, 'description');

  const isFormValid =
    getFormValue(serviceForm, 'hours') &&
    getFormValue(serviceForm, 'minutes') &&
    !!getFormValue(serviceForm, 'name') &&
    !!getFormValue(serviceForm, 'category') &&
    !!getFormValue(serviceForm, 'description');

  const calculateDuration = (hourCount: string, minuteCount: string) => {
    return Number(hourCount) * 60 + Number(minuteCount);
  };

  const formData = {
    name: getFormValue(serviceForm, 'name'),
    description: getFormValue(serviceForm, 'description'),
    price: getFormValue(serviceForm, 'price'),
    duration: calculateDuration(
      getFormValue(serviceForm, 'hours'),
      getFormValue(serviceForm, 'minutes')
    ),
    subCategoryId: getFormValue(serviceForm, 'category'),
    status,
    attendantList: getFormValue(serviceForm, 'attendants')
  };

  const redirectReferrer = (referrer: ReferrerType) => {
    return referrer === 'admin_profile'
      ? Constants.Links.Settings.Path.Profile
      : Constants.Links.Sidebar.Path.Settings;
  };

  const handleLeavingPage = async (referrer: ReferrerType) => {
    if (!isFormEmpty || isFormValid) {
      const link = redirectReferrer(referrer);
      setRoute(link);

      if(isFormValid) {
        if (serviceId === 'add') {
          setLoading(true);
          await addService(formData, referrer);
          setLoading(false);
        }
        history.push(link, { from: 'from-service-page', action: serviceId });
        return;
      } else {
        setShowConfirmationModal(true);
      }
    } else {
      const link = redirectReferrer(referrer);
      history.push(link);
    }
  };

  const handleSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      getFormValue(serviceForm, 'hours') === undefined ||
      getFormValue(serviceForm, 'minutes') === undefined
    ) {
      if (getFormValue(serviceForm, 'hours') === undefined) {
        setHideHourError(false);
      }
      if (getFormValue(serviceForm, 'minutes') === undefined) {
        setHideMinuteError(false);
      }
      return;
    }

    setLoading(true);
    try {
      const result =
        serviceId === 'add'
          ? await addService(formData)
          : await editService(serviceId as string, formData);
      setLoading(false);

      if (result) {
        history.push(Constants.Links.Sidebar.Path.Services);
      }
    } catch (error) {
      setFormErrors(error as FormErrorType);
      setLoading(false);
    }
  };

  const handleStatusCheck = (published: HTMLSelectElement) => {
    if (!published && confirmedBookingCount > 0) setShowDraftWarningModal(true);

    setStatus(published ? Constants.Statuses.Service.Published : Constants.Statuses.Service.Draft);
  };

  return (
    <React.Fragment>
      <CConfirmationModal
        show={showConfirmationModal}
        title="Save as draft"
        onClick={() => history.push(route)}
        onClose={() => setShowConfirmationModal(false)}
        hasStandardNote={false}
        customNote="You will lose your changes since the form is incomplete. Do you want to proceed?"
        nonCta="No"
        cta="Yes"
      />

      <CModal
        show={showDraftWarningModal}
        title="Save as draft"
        onClose={() => setShowDraftWarningModal(false)}
        footer={
          <div>
            <CButton loading={loading as boolean} small onClick={() => setShowDraftWarningModal(false)}>
              Ok
            </CButton>
          </div>
        }
      >
        <p>
          You are about to set a service that has <strong>{pluralize(confirmedBookingCount, 'confirmed booking')}</strong> to draft.
          New bookings will not be made, but old bookings will remain as scheduled.
        </p>

        <p className="mt-3">We will mail you the schedule if you choose to proceed.</p>
      </CModal>
      <OnboardingTaskList />

      <BaseHeader header={<ServiceBreadCrumb />} />

      <CPageCard>
        <CPageCard.Title>
          <h5>Service Information</h5>
          <p>Add a service name, description and other information</p>
        </CPageCard.Title>
        {formLoading ? <Loader /> : <CPageCard.Content>
          <Form onSubmit={handleSubmitForm} className="service__form">
            <div className="mb-3">
              <h5>Overview</h5>
              <Row className="mt-2">
                <Col md={6}>
                  <CFormInput
                    placeholder="Service Name"
                    type="text"
                    value={getFormValue(serviceForm, 'name')}
                    errors={formErrors['name']}
                    onInput={value =>
                      setFormValue(setServiceForm, 'name', value)
                    }
                  />
                </Col>
                <Col md={6}>
                  <CFormSelect
                    placeholder="Service Category"
                    name="serviceCategory"
                    value={getFormValue(serviceForm, 'category')}
                    errors={formErrors['sub_category']}
                    onInput={value =>
                      setFormValue(setServiceForm, 'category', value)
                    }
                    options={categories}
                    optionValue="id"
                    optionName="name"
                  />
                </Col>
              </Row>
              <CFormTextarea
                className="mt-3"
                placeholder="Service description"
                value={getFormValue(serviceForm, 'description')}
                errors={formErrors['description']}
                onInput={value =>
                  setFormValue(setServiceForm, 'description', value)
                }
              />
            </div>
            <div className="mb-3">
              <h5>Pricing &amp; Duration</h5>
              <Row className="mt-2">
                <Col md={6}>
                  <CFormInput
                    prefix="NGN"
                    placeholder=""
                    value={getFormValue(serviceForm, 'price')}
                    errors={formErrors['price']}
                    type="number"
                    onInput={value =>
                      setFormValue(setServiceForm, 'price', value)
                    }
                  />
                </Col>
                <Col md={3}>
                  <CFormInput
                    prefix="hours"
                    placeholder=""
                    type="number"
                    value={getFormValue(serviceForm, 'hours')}
                    errors={formErrors['duration']}
                    id="hours"
                    onInput={value => {
                      setFormValue(setServiceForm, 'hours', value);
                      setHideHourError(!isNaN(+value));
                    }}
                  />
                  <small className={`duration-error color-error ${hideHourError ? 'd-none' : ''}`}>
                    Enter a number
                  </small>
                </Col>
                <Col md={3}>
                  <CFormInput
                    prefix="mins"
                    placeholder=""
                    type="number"
                    value={getFormValue(serviceForm, 'minutes')}
                    id="mins"
                    onInput={value => {
                      setFormValue(setServiceForm, 'minutes', value);
                      setHideMinuteError(!isNaN(+value));
                    }}
                  />
                  <small className={`duration-error color-error ${hideMinuteError ? 'd-none' : ''}`}>
                    Enter a number
                  </small>
                </Col>
              </Row>
            </div>
            <div className="mb-4">
              <h5>Staff</h5>
              <p className={staff.length > 0 || adminBookable ? 'mb-3' : ''}>
                Assign attendants to this service
              </p>
              {adminBookable && staff.length === 0 &&
                <p className="small mb-3">
                  You can
                  <span>
                    <a
                      className="link-text"
                      onClick={() => handleLeavingPage('invite_staff')}
                    > invite</a> a staff
                  </span> or assign yourself as an attendant to this service.
                </p>
              }
              {!adminBookable && staff.length > 0 &&
                <p className="small mb-3">
                  You can assign attendants to this service or
                  <span>
                    <a
                      className="link-text"
                      onClick={() => handleLeavingPage('admin_profile')}
                    > set</a> yourself as bookable in your profile.
                  </span>
                </p>
              }

              {!adminBookable && staff.length === 0 &&
                <p className="small mb-3">
                  You can
                  <span>
                    <a
                      className="link-text"
                      onClick={() => handleLeavingPage('invite_staff')}
                    > invite</a> a staff
                  </span> or set yourself as bookable in your
                  <a
                    className="link-text"
                    onClick={() => handleLeavingPage('admin_profile')}
                  > profile.</a>
                </p>}
              <CMultiSelect
                options={attendants}
                placeholder="Select your attendants"
                defaultValues={defaultValues}
                onChange={value =>
                  setFormValue(setServiceForm, 'attendants', value)
                }
              />
            </div>
            <Row className="align-items-center">
              <Col md={6}>
                <CSlideCheckbox
                  name="status"
                  value={status === Constants.Statuses.Service.Published}
                  onInput={e => handleStatusCheck(e)}
                  text={titleize(status)}
                />
                <p>
                  <small>Let customers see this service</small>
                </p>
              </Col>

              <Col md={6} className="text-end">
                <CButton type="submit" loading={loading}>
                  {serviceId === 'add'
                    ? 'Create Service'
                    : 'Update Service'}
                </CButton>
              </Col>
            </Row>
          </Form>
        </CPageCard.Content>}
      </CPageCard>
    </React.Fragment>
  );
};

export default ServiceData;
