import { getResource, putResource } from './index';
import { Constants } from '../constants';
import {UserAttributeType} from '../types/auth_types';

export const getCustomerList = async (
  user: UserAttributeType,
  currentBusinessId: string,
  pageNumber: number = 1,
  q: string = ''
) => {
  return await getResource(
    `/v1/businesses/${currentBusinessId}/customers?page=${pageNumber}&per_page=${Constants.PerPageCount.Customers}&q=${q}`,
    user.token
  );
};

export const customerBlock = async (user: UserAttributeType, currentBusinessId: string, customerId: number) => {
  return await putResource(
    `/v1/businesses/${currentBusinessId}/customers/${customerId}/block`,
    {},
    user.token
  );
};

export const customerUnblock = async (user: UserAttributeType, currentBusinessId: string, customerId: number) => {
  return await putResource(
    `/v1/businesses/${currentBusinessId}/customers/${customerId}/unblock`,
    {},
    user.token
  );
};

export const getCustomerRatingList = async (
  user: UserAttributeType,
  currentBusinessId: string,
  customerId: number,
  pageNumber: number = 1
) => {
  return await getResource(
    `/v1/businesses/${currentBusinessId}/customers/${customerId}/ratings?page=${pageNumber}&per_page=${Constants.PerPageCount.CustomerRatings}`,
    user.token
  );
};
