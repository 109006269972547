import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import resetPasswordLogo from '../assets/images/reset-success.svg';
import CCard from '../shared/CCard';
import CButton from '../shared/forms/Button';
import {Constants} from '../utils/constants';

const ResetPasswordSuccess = () => {
  const history = useHistory();

  const goToLogin = () => {
    history.push(Constants.Links.Auth.Path.Login);
  };

  return (
    <div className="c-auth__gradient">
      <CCard
        content={
          <div className="c-auth__content">
            <img src={resetPasswordLogo} alt="Logo" />
            <br />
            <br />
            <h3>Password reset successful</h3>
            <p className="mt-2">Now you can get back into your account</p>
            <br />

            <CButton color="primary" onClick={goToLogin}>
              Sign in to Corna
            </CButton>
          </div>
        }
      />
    </div>
  );
};

export default withRouter(ResetPasswordSuccess);
