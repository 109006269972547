import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import TopServices from './TopServices';
import CustomerServicesChart from './CustomerServicesChart';
import AppointmentChart from './AppointmentChart';
import TopStaff from './TopStaff';
import { Constants } from '../../../utils/constants';
import CanceledBookings from './CanceledBookingsChart';
import CInlineFilter from '../../../shared/CInlineFilter';
import { AuthContext } from '../../../contexts/useAuthContext';
import { BusinessContext } from '../../settings/business-profile/useBusinessContext';
import Loader from '../../../shared/Loader';
import {monthDayYear, numericDate} from '../../../utils/stringManipulation';
import { dateFromToday } from '../../../utils/helpers';
import {FilterItemType} from '../../../utils/types/util_types';
import {AnalyticsDataObjectType, AnalyticsGraphDataByDateType, AnalyticsTopServiceByBookingType} from '../../../utils/types/analytics_types';

const DATA_TYPE = [
  {
    header: 'Top Staff',
    title: 'You’ve not had a booking yet',
    description: 'You’ll soon have information on your top performing staff.',
    type: Constants.Analytics.TopStaff
  },
  {
    header: 'Top Services',
    title: 'You’ve not any bookings in the last week',
    description: 'Looking forward to your bookings',
    type: Constants.Analytics.TopServices
  },
  {
    header: 'Categories & Services',
    title: 'You’ve not had a booking yet',
    description: 'You’ll soon have information on your top performing category.',
    type: Constants.Analytics.CategoriesAndServices
  },
  {
    header: 'Canceled Bookings',
    title: 'You’ve not had a cancellation',
    description: 'Good vibes on not seeing anything on this card',
    type: Constants.Analytics.BookingsCanceled
  }
];

const Analytics = () => {
  const [data, setData] = useState<AnalyticsDataObjectType>({} as AnalyticsDataObjectType);
  const [customerServiceRatio, setCustomerServiceRation] = useState({});
  const [completedBookingsGraph, setCompletedBookingsGraph] =
    useState<AnalyticsGraphDataByDateType>({} as AnalyticsGraphDataByDateType);
  const [canceledBookingsGraph, setCanceledBookingsGraph] = useState({});
  const [topServices, setTopServices] =
    useState<AnalyticsTopServiceByBookingType[]>([] as AnalyticsTopServiceByBookingType[]);
  const [topStaff, setTopStaff] = useState([]);
  const [topCategories, setTopCategories] = useState([]);
  const [showCustomDateFilter, setShowCustomDateFilter] = useState(false);
  const [dateFilterOption, setDateFilterOption] = useState('');
  const [showFilterClearButton, setShowFilterClearButton] = useState(false);
  const [isDateFilter, setIsDateFilter] = useState(false);
  const [showCalendarIcon, setShowCalendarIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const { state: authState } = useContext(AuthContext);
  const { getAnalyticsData } = useContext(BusinessContext);

  const timezone = authState.currentBusiness.attributes.timezone;

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getAnalyticsData(false, {
        date: {
          from: fromDate,
          to: toDate
        }
      } as FilterItemType);

      if (Object.keys(result).length === 0) return;

      setData(result);
      setCustomerServiceRation(result.service_customer_ratio);
      setCompletedBookingsGraph(result.completed_bookings_by_date);
      setTopServices(result.top_services_by_booking);
      setTopStaff(result.top_staff_by_booking);
      setTopCategories(result.top_categories_by_booking);
      setCanceledBookingsGraph(result.canceled_bookings_by_date);

      const last7Days = numericDate(
        dateFromToday(-6).toLocaleDateString(),
        timezone
      );

      const from = fromDate ? fromDate : last7Days;
      const to = toDate ? toDate : new Date().toLocaleDateString();

      setDateFilterOption(`${monthDayYear(from, timezone)} - ${monthDayYear(to, timezone)}`);
      setLoading(false);
    })();
  }, [authState.currentBusiness, fromDate, toDate]);

  const filterItems = [
    {
      title: Constants.Filter.Date.Value,
      submenu: [
        Constants.Filter.Date.Options.Today,
        Constants.Filter.Date.Options.Last7Days,
        Constants.Filter.Date.Options.Last30Days,
        Constants.Filter.Date.Options.Custom
      ]
    }
  ];

  const handleFilterChange = (obj: FilterItemType) => {
    setShowFilterClearButton(true);
    setShowCustomDateFilter(!!obj.date?.custom);
    setShowCalendarIcon(!!obj.date?.custom);
    setIsDateFilter(!!obj.date?.custom);
    setFromDate(obj.date?.from as string);
    setToDate(obj.date?.to as string);

    const from = monthDayYear(`${obj.date?.from}`, timezone);
    const to = monthDayYear(`${obj.date?.to}`, timezone);

    setDateFilterOption(`${from} - ${to}`);
  };

  const resetFilters = () => {
    setShowFilterClearButton(false);
    setShowCustomDateFilter(false);
    setShowCalendarIcon(false);
    setIsDateFilter(false);
    setFromDate('');
    setToDate('');
  };

  const toggleCalendar = () => {
    setShowCustomDateFilter(!showCustomDateFilter);
    setShowCalendarIcon(!showCalendarIcon);
  };

  const showData = Object.keys(completedBookingsGraph).length > 0 &&
    Object.keys(canceledBookingsGraph).length > 0 &&
    !loading;

  return (
    <div>
      <div className="d-flex justify-content-end">
        <p className="small pe-3 mt-2">{dateFilterOption}</p>
        <CInlineFilter
          values={filterItems}
          onChange={handleFilterChange}
          showCalendar={showCustomDateFilter}
          isDateFilter={isDateFilter}
          showCalendarIcon={showCalendarIcon}
          onToggle={() => toggleCalendar()}
        />
        {showFilterClearButton && (
          <button
            type="button"
            className="close ps-3"
            onClick={() => resetFilters()}
            title="Clear filter"
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close alert</span>
          </button>)}
      </div>
      {
        showData ? <div className="dashboard-empty__box">
          <div className="dashboard-empty__card w-50">
            <div className="d-flex justify-content-between">
              <Card className="home-card analytics-card">
                <div className="pt-2 ps-2">
                  <p className="small analytics-title">
                    Total No of customers
                  </p>

                  <h4>{data.total_number_of_customers}</h4>

                  <p className="small analytics-title">
                    No of customers in range
                  </p>

                  <h4>{data.customer_count_by_bookings}</h4>
                </div>
              </Card>

              <Card className="home-card analytics-card">
                <div className="pt-2 ps-2">
                  <p className="small analytics-title">
                    Total No of services
                  </p>

                  <h4>{data.total_number_of_services}</h4>

                  <p className="small analytics-title">
                    No of services in range
                  </p>

                  <h4>{data.service_count_by_bookings}</h4>
                </div>
              </Card>
            </div>

            <div className="d-flex justify-content-between mt-3">
              <Card className="home-card analytics-card">
                <div className="pt-2 ps-2">
                  <p className="small analytics-title pb-4">
                    No of customers that visited your business
                  </p>

                  <h3>N/A</h3>
                </div>
              </Card>

              <Card className="home-card analytics-card">
                <div className="pt-2 ps-2">
                  <p className="small analytics-title">
                    % of customers that visit your business without booking
                  </p>

                  <h3 className="mt-2">N/A</h3>
                </div>
              </Card>
            </div>
          </div>

          {Object.keys(completedBookingsGraph).length > 0 && <CustomerServicesChart data={customerServiceRatio} />}
          {Object.keys(completedBookingsGraph).length > 0 &&
            completedBookingsGraph.labels.length > 0 &&
            <AppointmentChart data={completedBookingsGraph} dateFilterOption={dateFilterOption} />
          }

          <div className="dashboard-empty__box m-0">
            {DATA_TYPE.map((item, key) => {
              if (item.type === Constants.Analytics.TopServices) {
                return Object.keys(completedBookingsGraph).length > 0 && <TopServices
                  {...item}
                  data={topServices}
                  key={key}
                  dateFilterOption={dateFilterOption}
                />;
              }
              if (item.type === Constants.Analytics.TopStaff) {
                return Object.keys(completedBookingsGraph).length > 0 && <TopStaff
                  {...item}
                  data={topStaff}
                  key={key}
                  dateFilterOption={dateFilterOption}
                />;
              }

              if (item.type === Constants.Analytics.CategoriesAndServices) {
                return Object.keys(completedBookingsGraph).length > 0 && <TopServices
                  {...item}
                  data={topCategories}
                  key={key}
                  category={true}
                  dateFilterOption={dateFilterOption}
                />;
              }

              if (item.type === Constants.Analytics.BookingsCanceled) {
                return Object.keys(canceledBookingsGraph).length > 0 && <CanceledBookings
                  {...item}
                  data={canceledBookingsGraph}
                  key={key}
                  dateFilterOption={dateFilterOption}
                />;
              }
            })}
          </div>
        </div> : <Loader />}
    </div>
  );
};

export default Analytics;
