import React, {useEffect} from 'react';
import Calendar from 'react-calendar';

interface Props {
  onDateChange: (a: any, b?: boolean) => void;
}
const BookingCalendar = ({ onDateChange }: Props) => {
  useEffect(() => {
    const today = new Date();
    onDateChange([today, today]);
  }, []);

  const onChange = (values: Date[]) => {
    let dates;

    if (values.length === 1) {
      dates = [values[0], values[0]];
    } else {
      dates = values;
    }

    onDateChange(dates, true);
  };

  return (
    <Calendar
      onChange={onChange}
      calendarType="US"
      allowPartialRange
      selectRange
    />
  );
};

export default BookingCalendar;

