import React from 'react';
import { Constants } from '../utils/constants';
import CButton from './forms/Button';

const NotFound = () => {
  return (
    <div className="c-auth__gradient">
      <img src={require('../assets/images/cycling-by.png')} alt="girl on bike" />
      <div className="c-auth__content">
        <h4 className="mb-3">Oops...you got a little lost</h4>
        <CButton color="primary" href={Constants.Links.Sidebar.Path.Home}>
          Head back home
        </CButton>
      </div>
    </div>
  );
};

export default NotFound;
