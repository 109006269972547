import { getResource } from './index';
import { Constants } from '../constants';
import {UserAttributeType} from '../types/auth_types';

export const getRevenueList = async (
  user: UserAttributeType,
  currentBusinessId: string,
  pageNumber: number = 1,
  q: string = '',
  status: string = '',
  from: string = '',
  to: string = ''
) => {
  return await getResource(
    `/v1/admin/businesses/${currentBusinessId}/invoices?page=${pageNumber}&per_page=${Constants.PerPageCount.Revenue}&q=${q}&status=${status}&from=${from}&to=${to}`,
    user.token
  );
};

export const getLineItemList = async (
  user: UserAttributeType,
  invoiceId: number,
  pageNumber: number = 1
) => {
  return await getResource(
    `/v1/admin/invoices/${invoiceId}/line_items?page=${pageNumber}&per_page=${Constants.PerPageCount.LineItems}`,
    user.token
  );
};
