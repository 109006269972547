import React, {useContext, useEffect, useState} from 'react';
import { Card } from 'react-bootstrap';
import { CDashboardTable, CDashboardTableData } from '../../shared/CDashboardTable';
import { CTableRow } from '../../shared/CTable';
import {convertDurationToHrsAndMins, convertTimeTo12HourFormat} from '../../utils/stringManipulation';
import { BookingContext } from './useBookingContext';
import { Constants } from '../../utils/constants';
import {urlParams} from '../../utils/helpers';
import {BookingStateObjectType} from '../../utils/types/booking_types';
import Loader from '../../shared/Loader';
import {AuthContext} from '../../contexts/useAuthContext';

const BookingTable = ({ date, open }: { date: string, open: boolean }) => {
  const [bookingsConfig, setBookingsConfig] = useState<BookingStateObjectType>({} as BookingStateObjectType);
  const [loading, setLoading] = useState(false);

  const { state: authState } = useContext(AuthContext);
  const { state, bookingList } = useContext(BookingContext);

  useEffect(() => {
    (async () => {
      if (!date || !open) return;

      setLoading(true);

      const data = await fetchData();

      if (!data) return;

      if (Object.keys(data as BookingStateObjectType).length === 0) {
        setLoading(false);
        return;
      }

      setBookingsConfig(data as BookingStateObjectType);
      setLoading(false);
    })();
  }, [date, open, window.location.href, authState.currentBusiness]);

  useEffect(() => {
    const { items = [], totalCount = 0, pageNumber } = state;
    setBookingsConfig({ items, totalCount, pageNumber } as BookingStateObjectType);
  }, [state]);

  const status = urlParams('status') || 'confirmed';

  const fetchData = async (
    pageNumber: number = 1,
    query: string = '',
    fetchAll: boolean = true
  ) => {
    if (!date) return;

    return await bookingList(pageNumber, query, status, date, date, fetchAll);
  };

  const tableConfig = {
    pageRange: Constants.PerPageCount.Bookings,
    totalCount: bookingsConfig.totalCount,
    onPageChange: async (page: number, query: string) => {
      if (bookingsConfig.totalCount > 0) await fetchData(page, query);
    }
  };

  return (
    <div className="dashboard-empty__card px-0">
      <Card className="home-card">
        <div className="dashboard-card__body py-0">
          {bookingsConfig.totalCount > 0 ?
            (loading ?
              <Loader /> :
              <CDashboardTable {...tableConfig}>
                {bookingsConfig.items.map(({ attributes }, index) => (
                  <CTableRow key={index} clickable={true} href={`/bookings/${attributes.id}`}>
                    <CDashboardTableData>
                      <strong>{attributes.service_name}</strong>
                      <span className="color-gray">{` for ${attributes.customer_name}`}</span>
                      <p className="small">
                        <small>
                          <strong>
                            {convertTimeTo12HourFormat(attributes.date, attributes.business_timezone)}
                          </strong>
                        </small>
                        <span className="color-gray">, </span>
                        <span className="color-gray">{convertDurationToHrsAndMins(attributes.service_duration)}</span>
                        <span className="color-gray">
                          <span className="color-gray"> &#8226; </span>
                          <span>{attributes.attendant_details.name}</span>
                        </span>
                      </p>
                    </CDashboardTableData>
                    <CDashboardTableData>
                      <strong>{attributes.service_amount}</strong>
                    </CDashboardTableData>
                  </CTableRow>
                ))}
              </CDashboardTable>) :
            <p className="p-1 text-center">No available data</p>
          }
        </div>
      </Card>
    </div>
  );
};

export default BookingTable;

