import {useState} from 'react';

const usePasswordValidator = () => {
  const [lowerCaseWarning, setLowerCaseWarning] = useState(false);
  const [upperCaseWarning, setUpperCaseWarning] = useState(false);
  const [digitWarning, setDigitWarning] = useState(false);
  const [specialCharWarning, setSpecialCharWarning] = useState(false);
  const [passwordCountWarning, setPasswordCountWarning] = useState(false);

  const validate = (value: string) => {
    const lowercase = /[a-z]/;
    const uppercase = /[A-Z]/;
    const digit = /[0-9]/;
    const specialChars = /[-!$%^&*()_+|~=`{}\\[\]:";'<>?,./]/;

    lowercase.test(value) ? setLowerCaseWarning(false) : setLowerCaseWarning(true);
    uppercase.test(value) ? setUpperCaseWarning(false) : setUpperCaseWarning(true);
    digit.test(value) ? setDigitWarning(false) : setDigitWarning(true);
    specialChars.test(value) ? setSpecialCharWarning(false) : setSpecialCharWarning(true);
    value && value.length >= 7 ? setPasswordCountWarning(false) : setPasswordCountWarning(true);
  };

  const isValid = (value: string | null) => {
    if (!value) return true;

    return !lowerCaseWarning && !upperCaseWarning && !digitWarning && !specialCharWarning && !passwordCountWarning;
  };

  return {
    validate,
    isValid,
    lowerCaseWarning,
    upperCaseWarning,
    digitWarning,
    specialCharWarning,
    passwordCountWarning
  };
};

export default usePasswordValidator;
