import React, { PropsWithChildren, useEffect, useReducer } from 'react';
import {
  loginAdmin,
  loginEmployee,
  registerUser,
  findEmployeeInvitation,
  findAdmin,
  updateAdmin,
  findEmployee,
  updateEmployee,
  verifyEmail,
  logoutUser,
  getUserForgottenPassword,
  resetUserPassword,
  accountReactivation,
  accountDeactivation,
  presetData,
  retrieveSubscription
} from '../utils/apiCalls/auth';
import { Constants } from '../utils/constants';
import {
  handleError,
  initializeState,
  isSubscriptionEnabled,
  successToast,
  updateContextState
} from '../utils/helpers';
import {
  AuthStateObjectType,
  AuthStateType,
  ProfileFormAttributeType,
  UserFormAttributeType,
  UserAttributeType
} from '../utils/types/auth_types';
import {BaseActionType, ApiErrorType, LinkType} from '../utils/types/util_types';
import { BusinessType } from '../utils/types/business_types';
import { StaffAttributeType } from '../utils/types/staff_types';
import {SubscriptionType} from '../utils/types/subscription_types';

const initialState = {
  currentBusinessId: null,
  currentBusiness: null,
  presetData: {},
  switchingBusiness: false,
  updateSubscriptionData: false,
  updateState: false,
  user: null
};

const AuthReducer = (state: AuthStateObjectType, action: BaseActionType): AuthStateObjectType => {
  switch (action.type) {
  case Constants.Actions.Auth.UserLoaded:
  case Constants.Actions.Auth.LogoutSuccess:
  case Constants.Actions.Auth.PresetSuccess:
  case Constants.Actions.Auth.CurrentBusinessChanged:
  case Constants.Actions.Auth.CurrentBusinessChanging:
  case Constants.Actions.Auth.InvitationRetrievalSuccess:
    return {
      ...state,
      ...action.payload
    } as AuthStateObjectType;
  case Constants.Actions.Auth.ResetUpdateState:
    return {
      ...state,
      updateState: !!action.payload
    } as AuthStateObjectType;
  default:
    return state;
  }
};

const actions = (dispatch: (_: any) => void, state: AuthStateObjectType) => {
  const formatUserPayload = (user: UserAttributeType) => {
    const { businesses } = user;
    const currentBusiness = businesses[0];
    return {
      user,
      currentBusiness,
      currentBusinessId: currentBusiness.id
    };
  };

  const findCurrentBusiness = (businesses: BusinessType[], business: BusinessType) => {
    const biz = businesses.find((b) => b.id === business.id);
    const currentBusiness = biz?.id == business.id ? business : biz;

    const user = {...state.user, businesses};

    return {
      currentBusiness,
      currentBusinessId: currentBusiness?.id,
      user
    };
  };

  const handleSubscriptionChange = (admin: any, user: UserAttributeType) => {
    const {data} = admin;
    const businesses = data.data.attributes.businesses;

    const currentBusiness = businesses.find((business: BusinessType) => business.id === state.currentBusiness.id);

    dispatch({
      type: Constants.Actions.Auth.UserLoaded,
      payload: {
        user: {
          ...data.data.attributes,
          token: user.token,
          refresh_token: user.refresh_token,
          businesses: businesses
        },
        currentBusiness,
        currentBusinessId: currentBusiness.id,
        updateSubscriptionData: true
      }
    });

    successToast(data.message);

    return data.data;
  };

  const resetUpdateState = (payload: boolean = true) => {
    dispatch({
      type: Constants.Actions.Auth.ResetUpdateState,
      payload
    });
  };

  const returnValue = {
    isAdmin: () => {
      return state.user.is_admin;
    },

    showIncomingPlanAlert: () => {
      if (!isSubscriptionEnabled) return false;

      return returnValue.isAdmin() &&
        !!state.user.subscription?.attributes?.incoming_plan_name &&
        (state.user.subscription.attributes.plan_code !==
          state.user.subscription.attributes.incoming_plan_code);
    },

    filterLinksWithAccess: (links: LinkType[]) => {
      return links.filter(({access, path}) => {
        const isLinkList = !returnValue.isAdmin() && access !== Constants.Access.All;

        if (isSubscriptionEnabled) {
          const {subscription} = state.user;

          if (subscription?.attributes?.plan_code !== process.env.REACT_APP_PAYSTACK_PLAN_PREMIUM_CODE) {
            return !(
              isLinkList ||
              path === Constants.Links.Sidebar.Path.Analytics ||
              path === Constants.Links.Sidebar.Path.CustomerDetails
            );
          }

          if (returnValue.isAdmin()) {
            return !(isLinkList || access === Constants.Access.Staff);
          } else {
            return !(isLinkList && access !== Constants.Access.Staff);
          }
        }

        if (returnValue.isAdmin()) {
          return !(isLinkList || access === Constants.Access.Hide || access === Constants.Access.Staff);
        } else {
          return !((isLinkList && access !== Constants.Access.Staff) || access === Constants.Access.Hide);
        }
      });
    },

    filterLinksWithSubscription: (links: LinkType[]) => {
      if (isSubscriptionEnabled) {
        const {subscription} = state.user;

        return links.filter(({access}) => {
          return !(
            subscription?.attributes.plan_code === process.env.REACT_APP_PAYSTACK_PLAN_BASIC_CODE &&
            access === Constants.Access.NotBasicAccount
          );
        });
      }

      return links.filter(({access}) => access);
    },

    getPresetData: async () => {
      // Load existing data from local storage before re-fetching updated data
      const storageData = localStorage.getItem(Constants.StorageKey.PresetData);
      if (storageData) {
        dispatch({
          type: Constants.Actions.Auth.PresetSuccess,
          payload: {presetData: JSON.parse(storageData)}
        });
        return true;
      }

      const response = await presetData();
      dispatch({
        type: Constants.Actions.Auth.PresetSuccess,
        payload: {presetData: response}
      });
      return true;
    },

    adminLogin: async ({email, password}: {email: string, password: string}) => {
      try {
        resetUpdateState();
        const user = await loginAdmin({email, password});
        const payload = formatUserPayload(user);
        dispatch({
          type: Constants.Actions.Auth.UserLoaded,
          payload
        });

        return payload;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    employeeLogin: async ({email, password}: {email: string, password: string}) => {
      try {
        resetUpdateState();
        const user = await loginEmployee({email, password});
        const payload = formatUserPayload(user);
        dispatch({
          type: Constants.Actions.Auth.UserLoaded,
          payload
        });

        return payload;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    setCurrentBusiness: (businesses: BusinessType[], business: BusinessType) => {
      dispatch({
        type: Constants.Actions.Auth.CurrentBusinessChanging,
        payload: { switchingBusiness: true }
      });

      const payload = findCurrentBusiness(businesses, business);

      dispatch({
        type: Constants.Actions.Auth.CurrentBusinessChanged,
        payload
      });

      resetUpdateState();

      dispatch({
        type: Constants.Actions.Auth.CurrentBusinessChanging,
        payload: { switchingBusiness: false }
      });
    },

    handlePlanChange: (subscription: SubscriptionType) => {
      const {user} = state;

      const withUpdatedPlan = {
        ...user.subscription?.attributes,
        incoming_plan_code: subscription.attributes.incoming_plan_code,
        incoming_plan_name: subscription.attributes.incoming_plan_name,
        plan_code: subscription.attributes.plan_code,
        plan_name: subscription.attributes.plan_name,
      };
      const updatedSubscription = {...user.subscription, attributes: withUpdatedPlan};

      dispatch({
        type: Constants.Actions.Auth.UserLoaded,
        payload: {
          user: {
            ...user,
            subscription: updatedSubscription
          }
        }
      });
    },

    register: async ({
      email,
      password,
      firstName,
      lastName,
      businessName,
      businessSize,
      businessCategory,
      agreement
    }: UserFormAttributeType) => {
      try {
        resetUpdateState();
        const user = await registerUser({
          email,
          password,
          firstName,
          lastName,
          businessName,
          businessSize,
          businessCategory,
          agreement
        });
        const payload = formatUserPayload(user);
        dispatch({
          type: Constants.Actions.Auth.UserLoaded,
          payload
        });

        return payload;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    retrieveEmployee: async (invitationToken: string) => {
      try {
        resetUpdateState();
        await findEmployeeInvitation(invitationToken);
        dispatch({
          type: Constants.Actions.Auth.InvitationRetrievalSuccess
        });
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    getAdmin: async () => {
      const { user } = state;

      try {
        resetUpdateState();

        const admin = await findAdmin(user);

        dispatch({
          type: Constants.Actions.Auth.UserLoaded,
          payload: {user: {...admin, token: user.token, refresh_token: user.refresh_token}}
        });

        return admin;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    editUser: async (attributes: UserFormAttributeType & ProfileFormAttributeType) => {
      const {user, currentBusinessId} = state;

      try {
        resetUpdateState();
        const profileId = user.profile.id;

        const admin = returnValue.isAdmin() ?
          await updateAdmin(user, profileId, attributes) :
          await updateEmployee(user, currentBusinessId, profileId, attributes);

        const {data} = admin;

        successToast(data.message);

        dispatch({
          type: Constants.Actions.Auth.UserLoaded,
          payload: {user: {...data.data.attributes, token: user.token, refresh_token: user.refresh_token}}
        });

        return data.data;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    getEmployee: async (staff?: StaffAttributeType) => {
      const {user, currentBusinessId} = state;

      try {
        resetUpdateState();
        const employee = !returnValue.isAdmin() ? user : staff;
        return await findEmployee(
          employee as StaffAttributeType,
          currentBusinessId,
          user
        );
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    logout: async () => {
      try {
        resetUpdateState();
        await logoutUser();
        dispatch({
          type: Constants.Actions.Auth.UserLoaded,
          payload: {...initialState}
        });
      } finally {
        resetUpdateState(false);
      }
    },

    verify: async ({confirmationToken}: {confirmationToken: string}) => {
      try {
        resetUpdateState();
        await verifyEmail(confirmationToken);

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    forgotPassword: async ({email}: {email: string}) => {
      try {
        resetUpdateState();
        await getUserForgottenPassword({email});

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    resetPassword: async ({token, password}: {token: string, password: string}) => {
      try {
        resetUpdateState();
        await resetUserPassword({token, password});

        return true;
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    },

    deactivateAccount: async () => {
      const {user} = state;

      try {
        resetUpdateState();
        const admin = await accountDeactivation(user);

        return handleSubscriptionChange(admin, user);
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    reactivateAccount: async () => {
      const {user} = state;

      try {
        resetUpdateState();
        const admin = await accountReactivation(user);

        return handleSubscriptionChange(admin, user);
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState();
      }
    },

    verifySubscription: async (reference: string) => {
      const {user} = state;

      try {
        resetUpdateState();
        await retrieveSubscription(user, reference);
      } catch (error) {
        handleError(error as ApiErrorType);
      } finally {
        resetUpdateState(false);
      }
    }
  };
  return returnValue;
};

type AuthContextActions = ReturnType<typeof actions>
type AuthContextType = AuthStateType & AuthContextActions
export const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children, value }: PropsWithChildren<{value?: AuthStateObjectType}>) => {
  const [state, dispatch] = useReducer(
    AuthReducer,
    initializeState(Constants.StorageKey.Session, { ...initialState, ...(value as AuthStateObjectType ?? {})})
  );

  useEffect(() => {
    if (!state) return;

    if (state.updateState) updateContextState(Constants.StorageKey.Session, state);
  }, [state]);

  return (
    <AuthContext.Provider value={{ state, ...actions(dispatch, state) }}>
      {children}
    </AuthContext.Provider>
  );
};
