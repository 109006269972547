import React, {ReactNode} from 'react';

const Chip = ({ children }: { children: ReactNode }) => {
  return (
    <div className="c-chip">
      {children}
    </div>
  );
};

export default Chip;
