import React from 'react';
import { Card } from 'react-bootstrap';
import LineChart from '../../../utils/charts/line';

const AppointmentChart = ({ data, dateFilterOption }) => {
  return (
    <div className="analytics-appointment-chart">
      <Card className="home-card">
        <Card.Body className="pt-3">
          <LineChart
            title="Total Appointments"
            labels={data.labels}
            yAxisData={data.y_axis}
            totalValue={data.additional_info}
            showBorder={false}
            noPadding={true}
            height="270px"
            dateFilter={dateFilterOption}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default AppointmentChart;
