import { deleteResource, getResource, postResource, putResource } from './index';
import {Constants} from '../constants';
import {UserAttributeType} from '../types/auth_types';

interface BankDetailProps {
  accountName?: string,
  accountNumber?: string,
  bankCode?: string,
  bankName?: string
}

export const getCardList = async (user: UserAttributeType) => {
  return await getResource(
    '/v1/cards',
    user.token
  );
};

export const createCard = async (user: UserAttributeType, reference: string) => {
  return await postResource(
    '/v1/verify_card',
    {reference: reference},
    user.token
  );
};

export const updateCard = async (user: UserAttributeType, cardId: number) => {
  return await putResource(
    `/v1/cards/${cardId}`,
    {},
    user.token
  );
};

export const updatePlan = async (user: UserAttributeType, planId: number) => {
  return await putResource(
    `/v1/admin/subscriptions/${user.subscription?.id}`,
    {plan_id: planId},
    user.token
  );
};

export const removeCard = async (user: UserAttributeType, cardId: number) => {
  return await deleteResource(
    `/v1/cards/${cardId}`,
    user.token
  );
};

export const findAccountName = async (
  user: UserAttributeType,
  businessId: string,
  accountNumber: string,
  bankCode: string
) => {
  return await getResource(
    `/v1/admin/businesses/${businessId}/account_name?account_number=${accountNumber}&bank_code=${bankCode}`,
    user.token
  );
};

export const createBankDetail = async (
  user: UserAttributeType,
  businessId: string, {
    accountName: account_name,
    accountNumber: account_number,
    bankCode: bank_code,
    bankName: bank_name
  }: BankDetailProps
) => {
  return await postResource(
    `/v1/admin/businesses/${businessId}/bank_details`,
    {
      bank_detail: {
        account_name,
        account_number,
        bank_code,
        bank_name
      }
    },
    user.token
  );
};

export const getBankDetailList = async (user: UserAttributeType, businessId: string) => {
  return await getResource(
    `/v1/admin/businesses/${businessId}/bank_details`,
    user.token
  );
};

export const updateBankDetail = async (user: UserAttributeType, businessId: string, bankDetailId: number) => {
  return await putResource(
    `/v1/admin/businesses/${businessId}/bank_details/${bankDetailId}`,
    {},
    user.token
  );
};

export const removeBankDetail = async (user: UserAttributeType, businessId: string, bankDetailId: number) => {
  return await deleteResource(
    `/v1/admin/businesses/${businessId}/bank_details/${bankDetailId}`,
    user.token
  );
};

export const getPaymentList = async (user: UserAttributeType, pageNumber: number) => {
  return await getResource(
    `/v1/admin/payments?page=${pageNumber}&per_page=${Constants.PerPageCount.Payments}`,
    user.token
  );
};
