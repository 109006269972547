import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import EmptyCard from '../../../shared/EmptyCard';
import { CDashboardTable, CDashboardTableData, CTopServiceRow } from '../../../shared/CDashboardTable';
import { Constants } from '../../../utils/constants';
import { paginateArray } from '../../../utils/helpers';
import {AnalyticsTopStaffByBookingType} from '../../../utils/types/analytics_types';

interface Props {
  header: string;
  data: AnalyticsTopStaffByBookingType[];
  title: string;
  description: string;
  dateFilterOption?: string;
}
const TopStaff = ({
  header,
  data,
  title,
  description,
  dateFilterOption
}: Props) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    (async () => {
      await fetchData(1, data);
    })();
  }, []);

  const tableConfig = {
    theadData: Constants.Table.Header.TopStaff,
    totalCount: data.length,
    pageRange: Constants.PerPageCount.Dashboard,
    onPageChange: async (page: number) => {
      if (data.length > 0) await fetchData(page, data);
    }
  };

  const fetchData = async (pageNumber = 1, data = [] as AnalyticsTopStaffByBookingType[]) => {
    paginateArray(Constants.PerPageCount.Dashboard, setValues, pageNumber, data);
  };

  return (
    <div className="dashboard-empty__card">
      <Card className="home-card">
        { data.length > 0 ? (
          <div className="dashboard-card__body">
            <p className="dashboard-card__title">
              <strong>{header}</strong>
              <small className="ms-2 color-gray">{dateFilterOption}</small>
            </p>
            <CDashboardTable {...tableConfig}>
              {values.map(({ first_name, last_name, booking_count }, index) => (
                <CTopServiceRow className="top-services-table__row" key={index}>
                  <CDashboardTableData>
                    <strong>{first_name} {last_name}</strong>
                  </CDashboardTableData>
                  <CDashboardTableData>
                    <strong>{booking_count}</strong>
                  </CDashboardTableData>
                </CTopServiceRow>
              ))}
            </CDashboardTable>
          </div>
        ) : (
          <EmptyCard
            title={title}
            header={header}
            description={description}
          />
        )}
      </Card>
    </div>
  );
};

export default TopStaff;
