import React, {useContext} from 'react';
import Sidebar from '../shared/Sidebar';
import BookingProvider from '../dashboard/bookings/useBookingContext';
import {AuthContext} from '../contexts/useAuthContext';
import Loader from '../shared/Loader';

const withBookingsProvider = (WrappedComponent: any) => ({ ...props }) => {
  const { state } = useContext(AuthContext);

  return (
    <div className="c-dashboard">
      <Sidebar />
      {state.switchingBusiness ? <Loader /> :
        <section className="c-dashboard__layout">
          <div className="c-dashboard__container">
            <BookingProvider>
              <WrappedComponent {...props} />
            </BookingProvider>
          </div>
        </section>
      }
    </div>
  );
};

export default withBookingsProvider;
