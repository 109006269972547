import {deleteResource, getResource, postResource, putResource} from './index';
import {JobRoleAttributeType} from '../types/staff_types';
import {Constants} from '../constants';
import {UserAttributeType} from '../types/auth_types';

interface StaffProps {
  firstName: string,
  lastName: string,
  email: string,
  role: number | string | JobRoleAttributeType
}

export const getStaffList = async (
  admin: UserAttributeType,
  currentBusinessId: string,
  pageNumber: number = 1,
  q: string = '',
  status: string = '',
  registered: boolean = false
) => {
  return await getResource(
    `/v1/businesses/${currentBusinessId}/employees?page=${pageNumber}&per_page=${Constants.PerPageCount.Staff}&q=${q}&status=${status}&registered=${registered}`,
    admin.token
  );
};

export const getRoleList = async (user: UserAttributeType) => {
  return await getResource(
    '/v1/job_roles',
    user.token
  );
};

export const createStaff = async (
  admin: UserAttributeType,
  currentBusinessId: string, {
    firstName: first_name,
    lastName: last_name,
    email,
    role
  }: StaffProps) => {
  return await postResource(
    `/v1/businesses/${currentBusinessId}/employees`,
    {
      employee: {
        first_name,
        last_name,
        email,
        job_role_attributes: {
          name: !isNaN(parseInt(role as string)) ? '' : role,
          id: !isNaN(parseInt(role as string)) ? role : ''
        }
      }
    },
    admin.token
  );
};

export const removeStaff = async (
  admin: UserAttributeType,
  currentBusinessId: string,
  id: number
) => {
  return await deleteResource(
    `/v1/businesses/${currentBusinessId}/employees/${id}`,
    admin.token
  );
};

export const updateStaff = async (
  user: UserAttributeType,
  currentBusinessId: string,
  employeeId: number, {
    firstName: first_name,
    lastName: last_name,
    email,
    role
  }: StaffProps
) => {
  const roleName = (role as JobRoleAttributeType)?.name;
  const roleId = (role as JobRoleAttributeType)?.id;

  return await putResource(
    `/v1/businesses/${currentBusinessId}/employees/${employeeId}`,
    {
      employee: {
        first_name,
        last_name,
        email,
        job_role_attributes: {
          name: roleName || (!isNaN(parseInt(role as string)) ? '' : role),
          id: roleId || (!isNaN(parseInt(role as string)) ? role : '')
        }
      }
    },
    user.token
  );
};

export const resendInvitation = async (admin: UserAttributeType, employeeId: number) => {
  return await postResource(
    `/v1/employee/${employeeId}/resend_invite`,
    {},
    admin.token
  );
};

export const reactivateAccount = async (
  admin: UserAttributeType,
  currentBusinessId: string,
  employeeId: number
) => {
  return await putResource(
    `/v1/businesses/${currentBusinessId}/employees/${employeeId}/reactivate`,
    {},
    admin.token
  );
};
