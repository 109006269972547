import React, {useState, useEffect, ChangeEvent} from 'react';

interface Props {
  value?: string | undefined;
  name?: string | undefined;
  placeholder?: string | undefined;
  errors?: string[] | undefined;
  onInput?: (_: any) => void;
  readOnly?: boolean | undefined;
  rows?: number;
  className?: string;
}

const CFormTextarea = ({
  value,
  name,
  placeholder,
  errors,
  onInput,
  readOnly,
  rows = 3,
  className = ''
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [hideError, setHideError] = useState(true);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  useEffect(() => {
    if (errors?.length) setHideError(false);
  }, [errors]);

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.persist();
    const { value } = e.target;
    setInputValue(value);
    onInput?.(value);
    if (value) {
      setHideError(true);
    }
  };

  return (
    <div>
      <div className={`c-input__box ${className}`}>
        <textarea
          className={`c-input ${inputValue ? 'label' : ''} ${
            errors?.length ? 'c-input__error' : ''
          }`}
          name={name}
          value={inputValue}
          onChange={handleInput}
          readOnly={readOnly}
          rows={rows}
        />
        <span className="c-input__placeholder textarea">{placeholder}</span>
      </div>
      {!hideError && errors?.length && (
        <ul>
          {errors.map((err, index) => (
            <li key={index} className="c-form__error">
              {err}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CFormTextarea;
