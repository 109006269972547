import React from 'react';
import { Card } from 'react-bootstrap';
import DoughnutChart from '../../../utils/charts/doughnut';

const CustomerServicesChart = ({ data }) => {
  return (
    <div className="dashboard-empty__card w-50">
      <Card className="home-card">
        <Card.Body className="pt-3">
          <p className="text-dark mb-2">
            <strong>% of Customers/Services Rendered</strong>
          </p>
          <div>
            <DoughnutChart dataset={data} labelFormat="percentage" />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CustomerServicesChart;
