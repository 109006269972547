import { getResource, postResource, putResource } from './index';
import {BusinessFormAttributeType} from '../types/business_types';
import {UserAttributeType} from '../types/auth_types';

export const findBusiness = async(user: UserAttributeType, currentBusinessId: string) => {
  return await getResource(
    `/v1/businesses/${currentBusinessId}`,
    user.token
  );
};

export const createBusiness = async(user: UserAttributeType, {
  name,
  category: category_id,
  description,
  email: contact_email,
  phoneNumber: contact_number,
  size,
  startTime: starting_time,
  closingTime: closing_time,
  images,
  address1,
  address2,
  postalCode: postal_code,
  city,
  latitude,
  longitude,
  timezone,
  stateCode: state_code,
  countryCode: country_code
}: BusinessFormAttributeType) => {
  return await postResource(
    '/v1/businesses', {
      business: {
        name,
        category_id,
        description,
        contact_email,
        contact_number,
        size,
        starting_time,
        closing_time,
        images,
        address_attributes: {
          address1,
          address2,
          postal_code,
          city,
          latitude,
          longitude,
          state_code,
          timezone,
          country_code
        }
      }
    },
    user.token
  );
};

export const updateBusiness = async (user: UserAttributeType, currentBusinessId: string, {
  name,
  category: category_id,
  description,
  email: contact_email,
  phoneNumber: contact_number,
  size,
  startTime: starting_time,
  closingTime: closing_time,
  images,
  addressId,
  address1,
  address2,
  postalCode: postal_code,
  city,
  latitude,
  longitude,
  timezone,
  stateCode: state_code,
  countryCode: country_code
}: BusinessFormAttributeType) => {
  return await putResource(
    `/v1/businesses/${currentBusinessId}`, {
      business: {
        name,
        category_id,
        description,
        contact_email,
        contact_number,
        size,
        starting_time,
        closing_time,
        images,
        address_attributes: {
          id: addressId,
          address1,
          address2,
          postal_code,
          city,
          latitude,
          longitude,
          timezone,
          state_code,
          country_code
        }
      }
    },
    user.token
  );
};

export const updateImages = async (user: UserAttributeType, currentBusinessId: string, images: string[]) => {
  return await putResource(
    `/v1/businesses/${currentBusinessId}`, {
      business: {
        images
      }
    },
    user.token
  );
};

export const getDashboardAnalytics = async (
  user: UserAttributeType,
  currentBusinessId: string,
  partial: boolean,
  from: string = '',
  to: string = ''
) => {
  return await getResource(
    `/v1/businesses/${currentBusinessId}/analytical_data?partial=${partial}&from=${from}&to=${to}`,
    user.token
  );
};
