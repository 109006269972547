import React, { ReactNode, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingIcon from '../../assets/images/loading-icon.svg';
import { getActiveClasses } from '../../utils/helpers';
import { ButtonType } from '../../types';

interface Props {
  disabled?: boolean;
  loading?: boolean;
  children?: ReactNode;
  onClick?: ((_:any) => void);
  href?: string | undefined;
  icon?: boolean | undefined;
  small?: boolean | undefined;
  smallNoLoader?: boolean | undefined;
  medium?: boolean | undefined;
  smallText?: boolean | undefined;
  fullWidth?: boolean | undefined;
  styles?: object | undefined;
  color?: string | undefined;
  type?: ButtonType;
  name?: string | undefined;
}

const CButton = ({
  disabled = false,
  loading = false,
  children,
  onClick,
  href,
  icon,
  small,
  smallNoLoader,
  medium,
  smallText,
  fullWidth,
  styles,
  name,
  color = 'primary',
  type = 'button'
}: Props) => {
  const history = useHistory();

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    if (type === 'submit') return;
    if (!href) {
      onClick?.(e);
      return;
    }
    history.push(href);
  };

  const getClass = () => {
    const colorClass = `c-btn__${color}`;
    const cssClasses = {
      'c-btn': true,
      [colorClass]: true,
      'c-btn__full-width': fullWidth,
      'c-btn__medium': medium,
      'c-btn__small': small,
      'c-btn__small--no-loader': smallNoLoader,
      'c-btn__small--text': smallText,
      'c-btn__icon': icon
    };
    return getActiveClasses(cssClasses);
  };
  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={handleClick}
      className={getClass()}
      aria-label={name}
      style={{ ...styles }}
    >
      {loading && (
        <img className="c-btn__loader" src={LoadingIcon} alt="Loader" />
      )}
      {children}
    </button>
  );
};

export default CButton;
