import React, {useContext} from 'react';
import { AuthContext } from '../../../contexts/useAuthContext';
import CardDetails from './CardDetails';
import BankDetails from './BankDetails';
import OnboardingTaskList from '../../../shared/OnboardingTaskList';

const SettingsPayments = () => {
  const { state: authState } = useContext(AuthContext);

  return (
    <div>
      <OnboardingTaskList />

      <CardDetails user={authState.user} />
      <br />
      <BankDetails />
    </div>
  );
};

export default SettingsPayments;
