import React, {useState, useEffect, ChangeEventHandler} from 'react';

interface Props {
  value?: string | undefined;
  name?: string | undefined;
  placeholder?: string | undefined;
  errors?: string[] | undefined;
  onInput?: (_: any) => void;
  options: any[];
  optionValue?: string;
  optionName?: string;
}

const CFormSelect = ({
  value,
  name,
  placeholder,
  errors,
  onInput,
  options = [],
  optionValue,
  optionName
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [hideError, setHideError] = useState(true);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  useEffect(() => {
    if (errors?.length) setHideError(false);
  }, [errors]);

  const handleInput: ChangeEventHandler<HTMLSelectElement> = (e) => {
    e.persist();
    const { value } = e.target;
    setInputValue(value);
    onInput?.(value);
    if (value) {
      setHideError(true);
    }
  };

  // using any because the options in a select can be of any type
  const getOptionValue = (option: any) => {
    return optionValue ? option[optionValue] : option;
  };

  // using any because the options in a select can be of any type
  const getOptionName = (option: any) => {
    return optionName ? option[optionName] : option;
  };

  return (
    <div>
      <div className="c-input__box">
        <select
          className={`c-input c-select ${inputValue ? 'label' : ''} ${
            errors?.length ? 'c-input__error' : ''
          }`}
          name={name}
          value={inputValue}
          onChange={handleInput}
        >
          <option value="" disabled hidden />
          {options.map(el => (
            <option key={getOptionValue(el)} value={getOptionValue(el)}>
              {getOptionName(el)}
            </option>
          ))}
        </select>
        <span className="c-input__placeholder">{placeholder}</span>
      </div>
      {!hideError && errors?.length && (
        <ul>
          {errors.map((err, index) => (
            <li key={index} className="c-form__error">
              {err}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CFormSelect;
