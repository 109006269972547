import React from 'react';
import { Card } from 'react-bootstrap';
import LineChart from '../../../utils/charts/line';
import EmptyCard from '../../../shared/EmptyCard';

const CanceledBookingChart = ({
  header,
  dateFilterOption,
  data,
  title,
  description
}) => {

  return (
    <div className="dashboard-empty__card w-50">
      <Card className="home-card">
        {
          data.labels.length > 0 ? (
            <LineChart
              title={header}
              labels={data.labels}
              yAxisData={data.y_axis}
              totalValue={data.additional_info}
              dateFilter={dateFilterOption}
              showBorder={false}
              height="226px"
            />
          ) : (
            <EmptyCard
              title={title}
              header={header}
              description={description}
              sad={false}
            />
          )
        }
      </Card>
    </div>
  );
};

export default CanceledBookingChart;
