import React from 'react';
import Select from 'react-select';
import {SelectOptionType} from '../../utils/types/util_types';

const styles = {
  control: (styles: object) => ({
    ...styles,
    borderColor: '#d6d6d6',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#008561'
    },
    '> div:first-of-type': {
      padding: '0.5rem 1rem'
    }
  }),
  multiValue: (styles: object) => {
    return {
      ...styles,
      alignItems: 'center',
      borderRadius: '20px',
      padding: '0px 5px',
      fontSize: '14px',
      backgroundColor: 'rgba(212, 233, 226, 0.45)',
      fontWeight: 'bold'
    };
  },
  multiValueLabel: (styles: object) => ({
    ...styles,
    color: '#008561'
  }),
  multiValueRemove: (styles: object) => ({
    ...styles,
    height: '22px',
    width: '22px',
    borderRadius: '50%',
    ':hover': {
      backgroundColor: '#008561',
      color: 'white'
    }
  })
} as object;

interface Props {
  placeholder?: string;
  defaultValues: string[];
  onInput?: (_: any) => void;
  options: any[];
  returnObject?: string;
  onChange?: (_: any) => void;
}

const CMultiSelect = ({
  placeholder = 'Select',
  defaultValues = [],
  options = [],
  returnObject,
  onChange
}: Props) => {

  const handleChange = (options: SelectOptionType[]) => {
    if (returnObject) {
      onChange?.(options);
      return;
    }
    const value = options.map(({ value }) => value);

    onChange?.(value);
  };

  // we have to add a conditional to the entire select component in order to show the already selected values on edit
  // since this component is rendered before the default values are set
  return (
    <React.Fragment>
      {defaultValues?.length > 0 && <Select
        onChange={handleChange}
        closeMenuOnSelect={false}
        defaultValue={defaultValues}
        isMulti
        styles={styles}
        options={options}
        placeholder={placeholder}
      />}

      {defaultValues?.length === 0 && <Select
        onChange={handleChange}
        closeMenuOnSelect={false}
        isMulti
        styles={styles}
        options={options}
        placeholder={placeholder}
      />}
    </React.Fragment>
  );
};

export default CMultiSelect;
