import React, {useContext} from 'react';
import Sidebar from '../shared/Sidebar';
import ServiceProvider from '../dashboard/services/useServiceContext';
import {AuthContext} from '../contexts/useAuthContext';
import Loader from '../shared/Loader';

const withServicesProvider = (WrappedComponent: any) => ({ ...props }) => {
  const { state } = useContext(AuthContext);

  return (
    <div className="c-dashboard">
      <Sidebar />
      {state.switchingBusiness ? <Loader /> :
        <section className="c-dashboard__layout">
          <div className="c-dashboard__container">
            <ServiceProvider>
              <WrappedComponent {...props} />
            </ServiceProvider>
          </div>
        </section>
      }
    </div>
  );
};

export default withServicesProvider;
