import instance from '../axios';
import { Constants } from '../constants';
import { logoutUser, getRefreshToken } from './auth';
import {
  errorToast,
  getLocalStorageItem,
  updateContextState,
  getContextState
} from '../helpers';

let refreshingFunc: any = undefined;
const MAX_REFRESH_COUNT = 3;

instance.interceptors.request.use(
  (config: any) => {
    const user = getContextState(Constants.StorageKey.Session, Constants.StorageKey.User);
    if (user) config.headers.Authorization = user.token;

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: any) => response,
  async function(error: any) {
    const response = error.response;
    if (response) {
      const { status } = response;
      if (status === 401) {
        let sessionRefreshCount = Number(localStorage.getItem(Constants.StorageKey.SessionRefreshCount));

        if (sessionRefreshCount === MAX_REFRESH_COUNT) {
          try {
            setTimeout(async () => {
              await logoutUser();
              window.location.href = `${Constants.Links.Auth.Path.Login}?action=time-out`;
            }, 120);
          } catch (e) {
            console.log('error');
          }
        } else {
          const originalConfig = error.config;
          try {
            // the trick here, that `refreshingFunc` is global, e.g. 2 expired requests will get the same function pointer and await same function.
            if (!refreshingFunc) {
              refreshingFunc = getRefreshToken();
            }

            const resp = await refreshingFunc;

            if (resp) {
              const newToken = resp.data.token;
              const newRefreshToken = resp.data.refresh_token;

              const mainState = getLocalStorageItem(Constants.StorageKey.MainKey);
              if (mainState) {
                const session = mainState[Constants.StorageKey.Session];
                const user = session.user;

                const userState = {...user, token: newToken, refresh_token: newRefreshToken};

                const newSessionState = {...session, user: userState};

                updateContextState(Constants.StorageKey.Session, newSessionState);
              }

              originalConfig.headers.Authorization = newToken;

              sessionRefreshCount += 1;
              localStorage.setItem(Constants.StorageKey.SessionRefreshCount, String(sessionRefreshCount));
            }
            try {
              return await instance.request(originalConfig);
            } catch (innerError) {
              console.log('oops');
            }
          } catch (err) {
            console.log('oopsie');
          } finally {
            refreshingFunc = undefined;
          }
        }
      }
      if (status === 404) {
        // TODO :: yet to decide how to handle a 404 error from the API
        // window.location.href = Constants.ExtraLinks.Path.NotFound;
      } else if (status === 403) {
        window.location.href = `${Constants.Links.Settings.Path.Plans}?action=upgrade`;
      } else if (status === 500) {
        errorToast(Constants.ErrorMessages[500]);
      }
    }
    return Promise.reject(response);
  }
);

export const getResource = (url: string, token?: string) => {
  return instance.get(url, { headers: { Authorization: token } });
};

export const postResource = (url: string, data: any, token?: string) => {
  return instance.post(url, data, { headers: { Authorization: token } });
};

export const putResource = (url: string, data?: any, token?: string) => {
  return instance.put(url, data, { headers: { Authorization: token } });
};

export const s3PutResource = (url: string, file: File) => {
  return instance.put(url, file, { headers: { 'Content-Type': file.type } });
};

export const deleteResource = (url: string, data?: any, token?: string) => {
  return instance.delete(url, { data: data, headers: { Authorization: token } });
};
