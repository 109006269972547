import React, {useContext} from 'react';
import Sidebar from '../shared/Sidebar';
import RevenueProvider from '../dashboard/revenue/useRevenueContext';
import {AuthContext} from '../contexts/useAuthContext';
import Loader from '../shared/Loader';

const withRevenueProvider = (WrappedComponent: any) => ({ ...props }) => {
  const { state } = useContext(AuthContext);

  return (
    <div className="c-dashboard">
      <Sidebar />
      {state.switchingBusiness ? <Loader /> :
        <section className="c-dashboard__layout">
          <div className="c-dashboard__container">
            <RevenueProvider>
              <WrappedComponent {...props} />
            </RevenueProvider>
          </div>
        </section>
      }
    </div>
  );
};

export default withRevenueProvider;
