import React, {useEffect, useState} from 'react';
import { Card } from 'react-bootstrap';
import EmptyCard from '../../../shared/EmptyCard';
import { CDashboardTable, CDashboardTableData, CTopServiceRow } from '../../../shared/CDashboardTable';
import CButton from '../../../shared/forms/Button';
import { Constants } from '../../../utils/constants';
import { paginateArray } from '../../../utils/helpers';
import {
  AnalyticsTopCategoryByBookingType,
  AnalyticsTopServiceByBookingType
} from '../../../utils/types/analytics_types';

interface Props {
  header: string;
  data: AnalyticsTopServiceByBookingType[];
  title: string;
  description: string;
  showMoreAnalytics?: boolean;
  dateFilterOption?: string;
  category?: boolean;
}
const TopServices = ({
  header,
  data,
  title,
  description,
  showMoreAnalytics,
  dateFilterOption = 'Last 7 days',
  category = false
}: Props) => {
  type TopServiceValueType = AnalyticsTopCategoryByBookingType & AnalyticsTopServiceByBookingType
  const [values, setValues] = useState<TopServiceValueType[]>([] as TopServiceValueType[]);

  useEffect(() => {
    (async () => {
      await fetchData(1, data);
    })();
  }, []);

  const tableConfig = {
    theadData: category ? Constants.Table.Header.TopCategories : Constants.Table.Header.TopServices,
    totalCount: data.length,
    pageRange: Constants.PerPageCount.Dashboard,
    onPageChange: async (page: number) => {
      if (data.length > 0) await fetchData(page, data);
    }
  };

  const fetchData = async (pageNumber = 1, data = [] as AnalyticsTopServiceByBookingType[]) => {
    paginateArray(Constants.PerPageCount.Dashboard, setValues, pageNumber, data);
  };

  return (
    <div className="dashboard-empty__card">
      <Card className="home-card">
        { data.length > 0 ? (
          <div className="dashboard-card__body">
            <p className="dashboard-card__title">
              <strong>{header}</strong>
              <small className="ms-2 color-gray">{dateFilterOption}</small>
              <span className="pull-right">
                {!category && showMoreAnalytics && <CButton
                  color="primary"
                  smallText
                  small
                  href={Constants.Links.Sidebar.Path.Analytics}
                >
                  View more
                </CButton>}
              </span>
            </p>
            <CDashboardTable {...tableConfig}>
              {values.map((attributes, index) => (
                <CTopServiceRow className="top-services-table__row" key={index}>
                  <CDashboardTableData>
                    <strong>{category ? attributes.category_name : attributes.name}</strong>
                  </CDashboardTableData>
                  <CDashboardTableData>
                    <strong>{category ? attributes.service_count_per_booking : attributes.booking_count}</strong>
                  </CDashboardTableData>
                </CTopServiceRow>
              ))}
            </CDashboardTable>
          </div>
        ) : (
          <EmptyCard
            title={title}
            header={header}
            description={description}
          />
        )}
      </Card>
    </div>
  );
};

export default TopServices;
