import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/useAuthContext';
import StaffProvider  from './useStaffContext';
import StaffTable from './StaffTable';
import CButton from '../../../shared/forms/Button';
import StaffData from './StaffData';

const SettingsStaff = () => {
  const [showModal, setShowModal] = useState(false);
  const { isAdmin } = useContext(AuthContext);

  return (
    <StaffProvider>
      {isAdmin() &&
        <div className="d-flex justify-content-between mb-4">
          <p>Create and manage your staff</p>
          <CButton small onClick={() => setShowModal(true)}>
            Add a new staff
          </CButton>
        </div>
      }
      <StaffTable onAddStaff={() => setShowModal(true)} />
      <StaffData
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </StaffProvider>
  );
};

export default SettingsStaff;
