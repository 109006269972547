import React, {useState, useContext, FormEvent} from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import forgotPasswordLogo from '../assets/images/forgot-password.svg';
import { AuthContext } from '../contexts/useAuthContext';
import CFormInput from '../shared/forms/Input';
import CButton from '../shared/forms/Button';
import CCard from '../shared/CCard';
import { validateEmail } from '../utils/formHelpers';
import AlertComponent from '../shared/AlertComponent';
import { Constants } from '../utils/constants';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const { forgotPassword } = useContext(AuthContext);

  const history = useHistory();

  const handleForgotPassword = async (e: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    setFormError('');
    e.preventDefault();
    try {
      const result = await forgotPassword({ email });
      setLoading(false);
      if (result) {
        history.push(Constants.Links.Auth.Path.ForgotPasswordSuccess);
      }
    } catch (error) {
      setFormError(error as string);
      setLoading(false);
    }
  };

  return (
    <div className="c-auth__gradient">
      <CCard
        content={
          <div className="c-auth__content">
            <img src={forgotPasswordLogo} alt="Logo" />
            <br />
            <br />
            <h1>Forgot Password?</h1>
            <p className="mt-2">
              Enter your email address and we'll send you a link to reset your
              password.
            </p>
            <br />
            <Form onSubmit={handleForgotPassword}>
              {formError && (
                <AlertComponent
                  text={formError}
                  type="danger"
                  onClose={() => setFormError('')}
                />
              )}
              <div className="mb-3">
                <CFormInput
                  placeholder="Email"
                  type="email"
                  name="email"
                  value={email}
                  onInput={setEmail}
                />
              </div>

              <Row className="align-items-center">
                <Col md={{ offset: 3, size: 9 } as any} className="text-end">
                  <CButton
                    color="primary"
                    type="submit"
                    loading={loading}
                    disabled={!validateEmail(email)}
                  >
                    Reset password
                  </CButton>
                </Col>
              </Row>
            </Form>
          </div>
        }
      />
      <div>
        <p className="mt-3 form-meta">
          Don't have an account?&nbsp;
          <a href={Constants.Links.Auth.Path.Register} className="link-text">
            Sign up
          </a>
        </p>
      </div>
    </div>
  );
};

export default withRouter(ForgotPassword);
