import React, {useEffect, useState} from 'react';
import AsyncCreatable  from 'react-select/creatable';
import {SelectOptionType} from '../../utils/types/util_types';

interface Props {
  placeholder?: string;
  options: any[];
  onChange?: (_: any) => void;
  defaultValue: SelectOptionType | undefined;
  errors?: string[];
  isValidNewOption?: (_: any) => void;
}

const CSearchableSelect = ({
  placeholder,
  options = [],
  onChange,
  defaultValue,
  errors,
  isValidNewOption
}: Props) => {
  const [hideError, setHideError] = useState(true);

  const styles = {
    control: (styles: object) => ({
      ...styles,
      borderColor: errors?.length ? '#ff3b30' : '#d6d6d6',
      boxShadow: 'none',
      ':hover': {
        borderColor: errors?.length ? '#ff3b30' : '#008561'
      },
      '> div:first-of-type': {
        padding: '0.5rem 1rem'
      }
    }),
    multiValue: (styles: object) => {
      return {
        ...styles,
        alignItems: 'center',
        borderRadius: '20px',
        padding: '0px 5px',
        fontSize: '14px',
        backgroundColor: 'rgba(212, 233, 226, 0.45)',
        fontWeight: 'bold'
      };
    },
    multiValueLabel: (styles: object) => ({
      ...styles,
      color: '#008561'
    }),
    multiValueRemove: (styles: object) => ({
      ...styles,
      height: '22px',
      width: '22px',
      borderRadius: '50%',
      ':hover': {
        backgroundColor: '#008561',
        color: 'white'
      }
    })
  } as object;

  useEffect(() => {
    setHideError(!errors);
  }, [errors]);

  const handleChange = (option: SelectOptionType) => {
    if (!option) {
      onChange?.('');
      return;
    }

    const { value } = option;
    onChange?.(value);
    if (value) {
      setHideError(true);
    }
  };

  return (
    <div>
      <AsyncCreatable
        placeholder={placeholder}
        isClearable
        options={options}
        defaultValue={defaultValue}
        styles={styles}
        onChange={handleChange}
        isValidNewOption={isValidNewOption}
      />

      {!hideError && errors?.length && (
        <ul>
          {errors.map((err, index) => (
            <li key={index} className="c-form__error">
              {err}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CSearchableSelect;
