import { deleteResource, getResource, postResource } from './index';
import { Constants } from '../constants';
import { UserAttributeType} from '../types/auth_types';
import {RatingFormAttributeType} from '../types/util_types';

export const getBookingList = async (
  user: UserAttributeType,
  currentBusinessId: string,
  pageNumber: number = 1,
  q: string = '',
  status: string = '',
  from: string = '',
  to: string = '',
  fetchAll: boolean = false
) => {
  return await getResource(
    `/v1/businesses/${currentBusinessId}/bookings?page=${pageNumber}&per_page=${Constants.PerPageCount.Bookings}&q=${q}&from=${from}&to=${to}&status=${status}&fetch_all=${fetchAll}`,
    user.token
  );
};

export const findBooking = async (user: UserAttributeType, currentBusinessId: string, id: number) => {
  return await getResource(
    `/v1/businesses/${currentBusinessId}/bookings/${id}`,
    user.token
  );
};

export const scoreBooking = async (user: UserAttributeType, currentBusinessId: string, id: number, {
  customer_note,
  customer_score
}: RatingFormAttributeType) => {
  return await postResource(
    `/v1/businesses/${currentBusinessId}/bookings/${id}/ratings`,
    {
      rating: {
        customer_note,
        customer_score
      }
    },
    user.token
  );
};

export const bookingCancellation = async (
  user: UserAttributeType,
  currentBusinessId: string,
  id: number,
  cancellation_note: string
) => {
  return await deleteResource(
    `/v1/businesses/${currentBusinessId}/bookings/${id}`,
    {cancellation_note: cancellation_note},
    user.token
  );
};
