import React from 'react';
import CModal from './Modal';
import CButton from './Button';

interface Props {
  show: boolean;
  title?: string;
  type?: string;
  loading?: boolean;
  onClick: ((_:any) => void);
  onClose: () => void;
  customNote?: JSX.Element | string | boolean | undefined;
  hasStandardNote?: boolean;
  nonCta?: string;
  cta?: string;
}

const CConfirmationModal = ({
  show,
  title,
  type,
  loading = false,
  onClick,
  onClose,
  customNote,
  hasStandardNote = true,
  nonCta = 'Cancel',
  cta = 'Delete'
}: Props) => {
  return (
    <CModal
      show={show}
      title={title || `${cta} ${type}`}
      onClose={onClose}
      footer={
        <div>
          <CButton color="transparent" onClick={onClose}>
            {nonCta}
          </CButton>
          <CButton loading={loading as boolean} onClick={onClick}>
            {cta}
          </CButton>
        </div>
      }
    >
      {customNote && <span>{customNote}</span>}
      {hasStandardNote && <p className="pt-3">{`Are you sure you want to ${cta?.toLowerCase()} this ${type}?`}</p>}
    </CModal>
  );
};

export default CConfirmationModal;
