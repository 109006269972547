import React, {useState, useContext, useEffect, FormEvent} from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Form, Col, Row } from 'react-bootstrap';
import { AuthContext } from '../contexts/useAuthContext';
import {logo, urlParams} from '../utils/helpers';
import CCard from '../shared/CCard';
import CFormInput from '../shared/forms/Input';
import CButton from '../shared/forms/Button';
import AlertComponent from '../shared/AlertComponent';
import { Constants } from '../utils/constants';
import usePasswordValidator from '../hooks/usePasswordValidator';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState('');
  const { resetPassword } = useContext(AuthContext);

  const history = useHistory();
  const passwordValidator = usePasswordValidator();

  useEffect(() => {
    passwordValidator.validate(password);
  }, [password]);

  useEffect(() => {
    if (!password || !confirmPassword) return;

    setDisabled(!isValid() || password !== confirmPassword);
  }, [password, confirmPassword]);

  const isValid = () => {
    return passwordValidator.isValid(password);
  };

  const handleResetPassword = async (e: FormEvent<HTMLFormElement>) => {
    setloading(true);
    setFormError('');
    e.preventDefault();
    const token = urlParams('token') || '';
    try {
      const result = await resetPassword({
        token,
        password
      });
      if (result) history.push(Constants.Links.Auth.Path.ResetPasswordSuccess);
    } catch (error) {
      setFormError(error as string);
    }

    setloading(false);
  };

  return (
    <div className="c-auth__gradient">
      <CCard
        content={
          <div className="c-auth__content">
            <img src={logo} alt="Logo" />
            <br />
            <br />
            <h1>Reset Password</h1>
            <br />

            <Form onSubmit={handleResetPassword}>
              {formError && (
                <AlertComponent
                  text={formError}
                  type="danger"
                  onClose={() => setFormError('')}
                />
              )}
              <div className="mb-3">
                <CFormInput
                  placeholder="Password"
                  type="password"
                  value={password}
                  onInput={setPassword}
                />
                {!isValid() &&
                  <div>
                    <li style={{ color: `${passwordValidator.lowerCaseWarning ? 'red' : 'green'}` }}>
                      contain at least one small letter
                    </li>
                    <li style={{ color: `${passwordValidator.upperCaseWarning ? 'red' : 'green'}` }}>
                      contain at least one capital letter
                    </li>
                    <li style={{ color: `${passwordValidator.digitWarning ? 'red' : 'green'}` }}>
                      contain at least one number letter
                    </li>
                    <li style={{ color: `${passwordValidator.specialCharWarning ? 'red' : 'green'}` }}>
                      contain at least one symbol (any character not a number or letter)
                    </li>
                    <li style={{ color: `${passwordValidator.passwordCountWarning ? 'red' : 'green'}` }}>
                      contain at least 7 characters
                    </li>
                  </div>
                }
              </div>
              <div className="mb-3">
                <CFormInput
                  placeholder="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onInput={setConfirmPassword}
                />
              </div>

              <Row className="align-items-center">
                <Col md={{ offset: 3, size: 9 } as any} className="text-end">
                  <CButton
                    color="primary"
                    type="submit"
                    loading={loading}
                    disabled={disabled}
                  >
                    Reset password
                  </CButton>
                </Col>
              </Row>
            </Form>
          </div>
        }
      />
      <div>
        <p className="mt-3 form-meta">
          Remember your password?&nbsp;
          <a href={Constants.Links.Auth.Path.Login} className="link-text">
            Sign in
          </a>
        </p>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
